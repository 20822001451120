import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AppSettings } from '../../../../app.settings';
import { Settings } from '../../../../app.settings.model';
import { MenuService } from '../menu.service';
import { Observable, Subscription } from 'rxjs';
import { RefreshState } from '../../../../store/usersession/usersession.store';
import { Store, select } from '@ngrx/store';
import { IAppState } from '../../../../store';

@Component({
  selector: 'app-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MenuService],
})
export class VerticalMenuComponent implements OnInit {
  @Input('menuItems') menuItems;
  @Input('menuParentId') menuParentId;
  parentMenu: Array<any>;
  public settings: Settings;

  refreshSub: Subscription;
  refresh$: Observable<RefreshState>;

  constructor(
    public appSettings: AppSettings,
    public menuService: MenuService,
    public router: Router,
    public store: Store<IAppState>,
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.refresh$ = this.store.pipe(select('refresh'));

    this.refreshSub = this.refresh$.subscribe((data) => {
      if (data.fiscalyear !== false) {
        this.refresh();
      }
    });
  }

  refresh() {
    this.parentMenu = this.menuItems.filter((item) => item.parentId == this.menuParentId);
    document.getElementsByClassName('sidenav-menu-outer')[0].scrollTop = 0;
  }

  ngOnChanges() {
    this.parentMenu = this.menuItems.filter((item) => item.parentId == this.menuParentId);
    document.getElementsByClassName('sidenav-menu-outer')[0].scrollTop = 0;
  }

  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.settings.fixedHeader) {
          let mainContent = document.getElementById('main-content');
          if (mainContent) {
            mainContent.scrollTop = 0;
          }
        } else {
          document.getElementsByClassName('mat-drawer-content')[0].scrollTop = 0;
        }
      }
    });
  }

  onClick(menuId) {
    this.menuService.toggleMenuItem(menuId);
    this.menuService.closeOtherSubMenus(this.menuItems, menuId);
  }

  onClick2(event: Event, menuId) {
    this.menuService.toggleMenuItem2(menuId);
    event.preventDefault();
    event.stopPropagation();
    // this.menuService.closeOtherSubMenus(this.menuItems, menuId);
  }
}
