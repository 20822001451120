import { Menu } from './menu.model';

export var credit = $localize`:@@credit:Credit Management`;
export var institute = $localize`:@@institute:Institute`;
export var reports = $localize`:@@reports:Reports`;
export var meetings = $localize`:@@meetings:Meetings`;
export var company_settings = $localize`:@@admin:Company Admin`;
export var admin = $localize`:@@admin:System Admin`;
export var companies = $localize`:@@admin:Client Company Admin`;
export var courses = $localize`:@@courses:Courses`;
export var users = $localize`:@@users:Users`;
export var all_users = $localize`:@@users:All Users`;
export var all_companies = $localize`:@@companies:All Companies`;
export var fiscalyears = $localize`:@@fiscalyears:Fiscal Years`;
export var depts = $localize`:@@depts:Dept`;
export var foundationdata = $localize`:@@foundationdata:Foundation Data`;
export var foundations = $localize`:@@foundations:Foundations`;
export var goals = $localize`:@@goals:Goals`;
export var tasks = $localize`:@@goals:Tasks`;
export var accolades = $localize`:@@goals:Accolades`;
export var concerns = $localize`:@@goals:Concerns`;
export var responsibilities = $localize`:@@goals:Responsibilities`;
export var performanceagreement = $localize`:@@goals:Performance Agreement`;
export var dashboard = $localize`:@@goals:Dashboard`;
export var myteam = $localize`:@@myteam:My Team`;
export var licensing = $localize`:@@licensing:Licensing`;

export const verticalMenuItems = [
  new Menu(1, dashboard, '/', null, 'dashboard', null, false, 0, []),
  new Menu(2, institute, '/institute', null, 'school', null, false, 0, []),
  new Menu(3, reports, '/reports', null, 'poll', null, false, 0, [
    'Administrator',
    'Consultant',
    'CompanyAdmin',
    'SeniorExec',
    'Manager',
  ]),
  new Menu(4, myteam, '/myteam', null, 'supervisor_account', null, false, 0, [
    'Administrator',
    'Consultant',
    'SeniorExec',
    'Manager',
  ]),
  new Menu(5, performanceagreement, '/perfagreement', null, 'task', null, false, 0, []),
  // new Menu (6, meetings, '/meetings', null, 'stop', null, false, 5, []),
  // new Menu(6, foundations, '/perfagreement/foundations', null, 'wysiwyg', null, false, 5, []),
  // new Menu(6, responsibilities, '/perfagreement/responsibilities', null, 'wysiwyg', null, false, 5, []),

  // new Menu(6, goals, '/perfagreement/goals', null, 'wysiwyg', null, false, 5, []),
  // new Menu(6, tasks, '/perfagreement/tasks', null, 'wysiwyg', null, false, 5, []),
  // new Menu(6, accolades, '/perfagreement/accolades', null, 'wysiwyg', null, false, 5, []),
  // new Menu(6, concerns, '/perfagreement/concerns', null, 'wysiwyg', null, false, 5, []),

  new Menu(7, company_settings, '/company', null, 'business', null, false, 0, [
    'Administrator',
    'Consultant',
    'CompanyAdmin',
    'SeniorExec',
    'Manager',
  ]),

  // new Menu(10, users, '/company/users', null, 'supervisor_account', null, false, 7, [
  //   'Administrator',
  //   'Consultant',
  //   'CompanyAdmin',
  //   'SeniorExec',
  //   'Manager',
  // ]),

  // new Menu(11, depts, '/company/depts', null, 'supervisor_account', null, false, 7, [
  //   'Administrator',
  //   'Consultant',
  //   'CompanyAdmin',
  //   'SeniorExec',
  //   'Manager',
  // ]),

  // new Menu(12, fiscalyears, '/company/fiscalyears', null, 'date_range', null, false, 7, [
  //   'Administrator',
  //   'Consultant',
  //   'CompanyAdmin',
  //   'SeniorExec',
  // ]),
  // new Menu(13, foundationdata, '/company/foundationdata', null, 'radar', null, false, 7, [
  //   'Administrator',
  //   'Consultant',
  //   'CompanyAdmin',
  //   'SeniorExec',
  // ]),

  new Menu(15, companies, '/admin', null, 'groups', null, true, 0, ['Administrator', 'Consultant']),
  new Menu(16, all_companies, '/admin/companies', null, 'business', null, false, 15, ['Administrator', 'Consultant']),
  new Menu(16, all_users, '/admin/users', null, 'supervisor_account', null, false, 15, ['Administrator', 'Consultant']),
  new Menu(17, licensing, '/admin/licensing', null, 'admin_panel_settings', null, false, 15, [
    'Administrator',
    'Consultant',
    'CompanyAdmin',
    'SeniorExec',
  ]),

  new Menu(18, admin, '/instituteadmin', null, 'settings_applications', null, true, 0, ['Administrator']),
  new Menu(19, courses, '/instituteadmin/courses', null, 'assignment', null, false, 18, ['Administrator']),
  new Menu(20, credit, '/instituteadmin/credits', null, 'money', null, false, 18, ['Administrator']),
];

export const horizontalMenuItems = [
  //new Menu (1, 'Dashboard', '/', null, 'dashboard', null, false, 0),
  new Menu(2, 'Users', '/users', null, 'supervisor_account', null, false, 0, ['Administrator']),
  new Menu(3, 'Enroll', '/enroll', null, 'assignment', null, false, 0, ['Administrator']),
];
