import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { IAppState } from '../store';
import { Observable, interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
@Component({
  selector: 'main-logo',
  template: 'main logo',
})
export class CompanyBreadcrumbComponent implements OnInit {
  constructor() {}

  private store: Store<IAppState>;
  ngOnInit() {}

  setStore(store: Store<IAppState>) {
    this.store = store;
  }

  breadcrumb(): Observable<any> {
    var company$ = this.store.pipe(select('company'));

    return company$.pipe(
      switchMap((r) =>
        r.company.pipe(
          map((m) => {
            return m.name;
          }),
        ),
      ),
    );
  }
}
