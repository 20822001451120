import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
  AuthState,
  SET_CURRENT_USER,
  ADD_IMPERSONATION,
  CLEAR_IMPERSONATION,
  SET_ORIG_USER,
  User,
  Company,
} from '../store/usersession/usersession.store';
import { MeetingScore } from './reports.service';

export class PerformancePeriod {
  public Id: Number;
  public CompanyId: Number;
  public StartDate: Date;
  public EndDate: Date;
  public IsClosed: Boolean;
  public IsActive: Boolean;
  public IsAlliance: Boolean;
  public CloseComments: String;
}

export class Meeting {
  public Id: Number;
  public Location: String;
  public ApprovalStatusId: Number;
  public MeetingTypeId: Number;
  public DateAndTime: Date;
  public EmployeeId: Number;
  public meeting_members: MeetingMember;
  public statusCode: String;
  public actionRequiredSelf: Boolean;
  public actionRequiredOther: Boolean;
  public meeting_action: MeetingAction;
  public meeting_status: MeetingStatus;
}

export class MeetingStatus {
  prescore_completed: Boolean;
}

export class MeetingAction {
  prescore_completed: Boolean;
  cancel: Boolean;
  prescore: Boolean;
  finalize: Boolean;
  meetnow: Boolean;
  delete: Boolean;
  save: Boolean;
  reschedule: Boolean;
  resendInvite: Boolean;
  future: Boolean;
  decline: Boolean;
  agree: Boolean;
  packet: Boolean;
  report: Boolean;
}

export class MeetingMember {
  id: Number;
  user_id: Number;
  user: User;
  meeting_score: MeetingScore;
}

export class MeetingScores {
  initiatives: MeetingScoreInput[];
  pjr: MeetingScoreInput[];
  goals: MeetingScoreInput[];
  cb: MeetingScoreInput[];
}

export class MeetingScoreInput {
  id: number;
  score: number;
}

export class TeamMember {
  public UserId: number;
  FirstName: string;
  LastName: string;
  CompanyId: number;
  CompanyName: string;
  ManagerId: number;
  IsPartnerMeeting: boolean;
  IsClosed: boolean;
  PerformanceAgreementId: number;
  IsApproved: boolean;
  AgreeStartDate: Date;
  AgreeEndDate: Date;
  Meetings: Meeting[] = [];
  Id: number;
}

@Injectable()
export class CompanyService {
  constructor(private http: HttpClient) {}
  getCompanies(): Observable<Company[]> {
    return this.http.get<Company[]>('/api/companies');
  }

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>('/api/company/users');
  }

  getPerformancePeriods(): Observable<PerformancePeriod[]> {
    return this.http.get<PerformancePeriod[]>('/api/company/performanceperiods');
  }

  getMeetings(fiscalYearId: Number): Observable<Meeting[]> {
    return this.http.get<Meeting[]>(`/api/company/meetings?fiscalYearid=${fiscalYearId}`);
  }

  getTeamMembers(fiscalYearId: Number): Observable<TeamMember[]> {
    return this.http.get<TeamMember[]>(`/api/company/teammembers?fiscalYearid=${fiscalYearId}`);
  }

  scheduleMeeting(Location: string, employeeId: number, dateTime: Date): Observable<any> {
    let postData: any = {
      Location: Location,
      DateAndTime: dateTime.getTime(),
      EmployeeId: employeeId,
    };
    return this.http.post<any>(`/api/company/meetings`, postData);
  }
}
