import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  AuthState,
  SET_CURRENT_USER,
  ADD_IMPERSONATION,
  CLEAR_IMPERSONATION,
  SET_ORIG_USER,
  User,
  Company,
  UPDATE_NOTIFY_COUNT,
  SEND_REFRESH,
} from '../store/usersession/usersession.store';
import { IAppState } from '../store/index';
import { InstituteService } from './institute.service';
import { Apollo, gql } from 'apollo-angular';
import { take } from 'rxjs/operators';

const GET_APPROVALS = gql`
  query {
    approvals {
      id
      type
      desc
      user {
        Id
        FirstName
        LastName
      }
    }
  }
`;

@Injectable()
export class UserService {
  public CurrentUser: User = new User();
  public OrigUser: User = new User();
  public Impersonations: any[] = [];

  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store<IAppState>,
    private instituteService: InstituteService,
    private apollo: Apollo,
  ) {}
  ensureCurrentUser() {
    if (!this.CurrentUser) {
      // We don't have one, so get one.

      console.log('ghghjghjghjgjghjg');
      // todo: figure out the sessionStorage
      const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
      if (currentUser) {
        console.log('Read from parsed type.');
        this.CurrentUser = currentUser;

        this.store.dispatch({
          type: SET_CURRENT_USER,
          payload: this.CurrentUser,
        });
        this.OrigUser = JSON.parse(sessionStorage.getItem('origUser'));
        this.store.dispatch({
          type: SET_ORIG_USER,
          payload: this.OrigUser,
        });
        const tImp = null;
        JSON.parse(sessionStorage.getItem('impersonations'));
        if (Array.isArray(tImp)) {
          for (let i = 0; i < tImp.length; i++) {
            this.store.dispatch({
              type: ADD_IMPERSONATION,
              payload: tImp[i],
            });
          }
        }
      }
    } else {
      console.log('exists');
      // 5 minute interval
      if (!this.CurrentUser.lastSeen) {
        this.CurrentUser.lastSeen = Date.now();
        this.store.dispatch({
          type: SET_CURRENT_USER,
          payload: this.CurrentUser,
        });
      }
      if (new Date(this.CurrentUser.lastSeen).getDate() + 300000 < Date.now()) {
        this.http
          .post('/api/company/touch', JSON.stringify({}), {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            withCredentials: true,
          })
          .pipe(map((response: HttpResponse<any>) => {}))
          .subscribe(
            (data) => {
              // We are still logged in on the server so refresh the client copy.
              this.CurrentUser.lastSeen = Date.now();

              this.store.dispatch({
                type: SET_CURRENT_USER,
                payload: this.CurrentUser,
              });
            },
            (err) => {
              sessionStorage.removeItem('currentUser');
              sessionStorage.removeItem('origUser');
              sessionStorage.removeItem('impersonations');
              this.store.dispatch({
                type: SET_CURRENT_USER,
                payload: new User(),
              });
              this.store.dispatch({
                type: SET_ORIG_USER,
                payload: new User(),
              });
              this.store.dispatch({
                type: CLEAR_IMPERSONATION,
                payload: [],
              });
              //     this.router.navigate(['/login']);
            },
          );
      } // End of Last seen check
    }
  }
  initializeSession(user: any) {
    // console.log('test');

    user = Object.assign({}, user);
    console.log(user);
    user.lastSeen = Date.now();
    if (user.Icon == null) {
      user.Icon = 'assets/img/users/default-user.jpg';
    }
    this.CurrentUser = user;
    // console.log('dispatch: ' + JSON.stringify(this.CurrentUser));
    //  sessionStorage.setItem('currentUser', JSON.stringify(this.CurrentUser));
    //  sessionStorage.setItem('origUser', JSON.stringify(this.OrigUser));
    //  sessionStorage.setItem('impersonations', JSON.stringify([]));

    // this.store.dispatch({
    //         type: CLEAR_IMPERSONATION,
    //         payload: []
    //     });
    this.store.dispatch({
      type: SET_CURRENT_USER,
      payload: this.CurrentUser,
    });

    this.store.dispatch({
      type: SEND_REFRESH,
    });

    // setInterval(() => {

    //     this.apollo.query<any>({query: GET_APPROVALS, fetchPolicy: 'network-only'}).subscribe(({ data, loading }) => {
    //         this.store.dispatch({
    //             type: UPDATE_NOTIFY_COUNT,
    //             payload: data.approvals.length
    //         })
    //       });

    // }, 30000);

    this.apollo.query<any>({ query: GET_APPROVALS, fetchPolicy: 'network-only' }).subscribe(({ data, loading }) => {
      this.store.dispatch({
        type: UPDATE_NOTIFY_COUNT,
        payload: data.approvals.length,
      });
    });

    // this.store.dispatch({
    //         type: SET_ORIG_USER,
    //         payload: this.OrigUser
    //     });
  }

  login(EmailAddress: string, Password: string) {
    return this.http
      .post('/api/company/login', JSON.stringify({ username: EmailAddress, password: Password }), {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        withCredentials: true,
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          let user = Object.assign({}, response[0][0]);
          let company = Object.assign({}, response[1][0]);
          if (company == null) {
            company = new Company();
          }
          user.Company = company;

          this.store.dispatch({
            type: SET_ORIG_USER,
            payload: user,
          });

          this.initializeSession(user);
        }),
      );
  }

  loginK3Session() {
    return this.http
      .post('/api/company/k3auth', JSON.stringify({ k3: 'auth' }), {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        withCredentials: true,
      })
      .pipe(
        catchError((error: any, caught: Observable<Object>) => {
          return of(false);
        }),
        map((response: HttpResponse<any>) => {
          if (response) {
            let user = Object.assign({}, response[0][0]);
            let company = Object.assign({}, response[1][0]);
            if (company == null) {
              company = new Company();
            }
            user.Company = company;

            this.store.dispatch({
              type: SET_ORIG_USER,
              payload: user,
            });
            this.initializeSession(user);
            return true;
          }
          return false;
        }),
      );
  }

  logout() {
    // remove user from local storage to log user out

    return this.http
      .post('/api/company/logout', JSON.stringify({}), {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        withCredentials: true,
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          //  sessionStorage.removeItem('currentUser');
          //  sessionStorage.removeItem('origUser');
          //  sessionStorage.removeItem('impersonations');
          this.store.dispatch({
            type: CLEAR_IMPERSONATION,
            payload: [],
          });
          this.store.dispatch({
            type: SET_CURRENT_USER,
            payload: new User(),
          });
          this.store.dispatch({
            type: SET_ORIG_USER,
            payload: new User(),
          });
          // this.router.navigate(['/login']);
        }),
      );
  }
  isLoggedIn(roleRequired: string) {
    if (roleRequired) {
      if (this.CurrentUser.Roles.indexOf(roleRequired) !== -1) {
        return true;
      }
    } else {
      if (this.CurrentUser.EmailAddress && this.CurrentUser.EmailAddress !== '') {
        //console.log(this.CurrentUser.EmailAddress);
        return true;
      }
    }
  }

  listUsers(EmailAddress: string, FirstName: string, LastName: string): Observable<User[]> {
    return this.http
      .post<User[]>(
        '/api/company/users/list',
        JSON.stringify({ EmailAddress: EmailAddress, FirstName: FirstName, LastName: LastName }),
        {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
          withCredentials: true,
        },
      )
      .pipe(map((res) => res));
  }

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>('/api/company/users');
  }

  saveUser(usr: User) {
    return this.http
      .post('/api/company/users/save', JSON.stringify(usr), {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        withCredentials: true,
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          if (usr.UserID === this.CurrentUser.UserID) {
            usr.lastSeen = Date.now();
            this.store.dispatch({
              type: SET_CURRENT_USER,
              payload: usr,
            });

            if (usr.UserID === this.OrigUser.UserID) {
              this.store.dispatch({
                type: SET_ORIG_USER,
                payload: usr,
              });
            }
          }
        }),
      );
  }
  statusUser(usr: User) {
    return this.http
      .post('/api/company/users/status', JSON.stringify(usr), {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        withCredentials: true,
      })
      .pipe(map((response: HttpResponse<any>) => {}));
  }
  impersonate(UserID: number) {
    const InList = false;

    return this.http
      .post('/api/company/impersonate', JSON.stringify({ username: UserID }), {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        withCredentials: true,
      })
      .pipe(
        map((response: HttpResponse<User>) => {
          let user = Object.assign({}, response[0][0]);
          let company = Object.assign({}, response[1][0]);
          if (company == null) {
            company = new Company();
          }
          user.Company = company;

          this.initializeSession(user);
          this.store.dispatch({
            type: ADD_IMPERSONATION,
            payload: user,
          });
          this.router.navigate(['/']);
        }),
      );
  }
}
/*
getCompanies() {

        return  this.http.get('/api/company').map(res => res.json());
    }
*/
