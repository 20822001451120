import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
  AuthState,
  SET_CURRENT_USER,
  ADD_IMPERSONATION,
  CLEAR_IMPERSONATION,
  SET_ORIG_USER,
  User,
  Company,
  UPDATE_MODULE,
  UPDATE_COURSE,
} from '../store/usersession/usersession.store';
import { AnyFn } from '@ngrx/store/src/selector';
import { Store } from '@ngrx/store';
import { IAppState } from '../store';

export class Course {
  public CourseID: Number;
  public CategoryID: Number;
  public CourseName: String;
  public CourseDescription: String;
  public Icon: String;
  public CreatedBy: Number;
  public CreatedDate: Date;
  public Credits: Number;
  public EstimatedTime: Number;
  public RequiresCourseID: Number;
  public SortOrder: Number;
  public File: any;

  constructor(public blah: String, public blah2: String) {
    this.CourseName = blah;
    this.CourseDescription = blah2;
  }
}

export class CourseEnrollment {
  public CourseID: Number;
  public CategoryID: Number;
  public CourseName: String;
  public CourseDescription: String;
  public Icon: String;
  public HeroImage: String;
  public CreatedBy: Number;
  public CreatedDate: Date;
  public Credits: Number;
  public EstimatedTime: Number;
  public RequiresCourseID: number;
  public SortOrder: Number;
  public RequiresRole: string;
  public IsCompleted: Number;
  public Eligible: Number;
  public Approved;
  Boolean;
}

export class CourseEnroll extends Course {
  public EnrollID: Number = -1;
  public DueDate: Date = null;
  public IsCompleted: Boolean = false;
}
export class CourseGroup {
  public CategoryID: Number = -1;
  public CategoryName: String = '';
}

export class CourseModule {
  public ModuleID: Number;
  public CourseID: Number;
  public ModuleName: String;
  public ModuleDescription: String;
  public ModuleTypeID: Number;
  public EstimatedTime: Number;
  public PassingGrade: Number;
  public SortOrder: Number;
  public IsActive: Boolean;
  public FileName: String;
}

export class CourseModuleAssignment {
  public CourseEnrollID: Number;
  public Status: Number;
  public Grade: Number;
  public ModuleID: Number;
  public CourseID: Number;
  public ModuleName: String;
  public ModuleDescription: String;
  public ModuleTypeID: Number;
  public EstimatedTime: Number;
  public PassingGrade: Number;
  public SortOrder: Number;
  public IsActive: Boolean;
  public FileName: String;
  public File: String;
}

export class CourseModuleType {
  public ModuleTypeID: Number;
  public ModuleTypeDesc: String;
}

export class Webinar {
  public ModuleID: Number;
  public StartTime: Date;
  public Duration: Number;
  public LeadUserID: Number;
  public MinStudents: Number;
  public MaxStudents: Number;
  public WebinarKey: String;
  public AttendanceCompleted: Boolean;
}

export class WebinarRegistration {
  public StartTime: Date;
  public Duration: Number;
  public WebinarKey: String;
  public ModuleID: Number;
  public UserID: Number;
  public RegistrantKey: String;
  public CourseEnrollID: Number;
  public WebinarJoinURL: String;
  public Attended: Boolean;
  public Enrolled: Boolean;
}

export class TestQuestions {
  public Questions: Question[];
  public Answers: Answer[];
  public Test: Test;
}

export class QuestionAnswers {
  public Question: Question;
  public Answers: Answer[];
  public Test: Test;
}

export class Test {
  public TestID: Number;
  public Grade: Number;
  public StartTime: Date;
  public EndTime: Date;
  public TestName: String;
  public PassingGrade: Number;
  public Passed: Boolean;
}

export class Question {
  ModuleID: Number;
  QuestionID: Number;
  QuestionText: String;
  QuestionType: Number;
}

export class Answer {
  QuestionID: Number;
  AnswerID: Number;
  AnswerLabel: String;
  IsCorrect: Boolean;
}

export class TestResults {
  score: Number;
  gradedQuestions: GradedQuestion[];
  Passed: Boolean;
  PassingGrade: Number;
}

export class GradedQuestion {
  QuestionID: Number;
  IsCorrect: Boolean;
  Answer: String;
  AnswerValue: String;
  AnswerID: Number;
  CorrectAnswer: String;
  QuestionText: String;
  AnswerText: String;
}

export class CourseProgressReport {
  UserID: Number;
  FirstName: String;
  LastName: String;
  ManagerId: Number;
  WhiteBeltCompleted: Boolean;
  YellowBeltCompleted: Boolean;
  OrangeBeltCompleted: Boolean;
  GreenBeltCompleted: Boolean;
  BlueBeltCompleted: Boolean;
  RedBeltCompleted: Boolean;
  BlackBeltCompleted: Boolean;
}

export class KNotification {
  TranID: Number;
  Amount: Number;
  CourseID: 1;
  UserID: 1;
  Name: String;
  CourseName: String;
}

export class Credit {
  CompanyID: Number;
  CompanyName: String;
  ConsumedCredits: Number;
  AvailableCredits: Number;
}

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
@Injectable()
export class InstituteService {
  constructor(private http: HttpClient, private store: Store<IAppState>) {}
  getCourseList(): Observable<CourseEnrollment[]> {
    return this.http.get<CourseEnrollment[]>('/api/tki/catalog/list');
  }

  getCourses(): Observable<Course[]> {
    return this.http.get<Course[]>('/api/tki/catalog/courses');
  }

  getEnrollments(): Observable<CourseEnroll[]> {
    return this.http.get<CourseEnroll[]>('/api/tki/catalog/courseenrollments');
  }

  enrollCourse(courseId: Number): Observable<Number> {
    return this.http.get<Number>(`/api/tki/catalog/course/${courseId}/enroll`);
  }

  getCourse(courseId: Number): Observable<Course> {
    var course: Observable<Course> = this.http.get<Course>(`/api/tki/catalog/course/${courseId}`);
    this.store.dispatch({
      type: UPDATE_COURSE,
      payload: course,
    });

    return course;
  }

  saveCourse(formData: FormData): Observable<any> {
    return this.http.post('/api/tki/catalog/course', formData);
  }

  updateCourse(formData: FormData, courseId: Number): Observable<any> {
    return this.http.put(`/api/tki/catalog/course/${courseId}`, formData);
  }

  deleteCourse(courseId: Number): Observable<any> {
    return this.http.delete(`/api/tki/catalog/course/${courseId}`, httpOptions);
  }

  getCourseModules(courseId: Number): Observable<CourseModule[]> {
    return this.http.get<CourseModule[]>(`/api/tki/catalog/course/${courseId}/modules`);
  }

  getCourseModule(moduleId: Number): Observable<CourseModule> {
    var courseModule = this.http.get<CourseModule>(`/api/tki/catalog/module/${moduleId}`);
    // this.store.dispatch({
    //     type: UPDATE_MODULE,
    //     payload: courseModule
    // })

    return courseModule;
  }

  saveCourseModule(formData: FormData, courseID: Number): Observable<any> {
    return this.http.post(`/api/tki/catalog/course/${courseID}/module`, formData);
  }

  updateCourseModule(formData: FormData, moduleID: Number): Observable<any> {
    return this.http.put(`/api/tki/catalog/module/${moduleID}`, formData);
  }

  getCourseModuleAssignments(courseId: Number): Observable<CourseModuleAssignment[]> {
    return this.http.get<CourseModuleAssignment[]>(`/api/tki/catalog/course/${courseId}/module-assignments`);
  }

  getCourseModuleAssignment(courseId: Number, moduleId: Number): Observable<CourseModuleAssignment> {
    var courseModuleAssignment = this.http.get<CourseModuleAssignment>(
      `/api/tki/catalog/course/${courseId}/module-assignment/${moduleId}`,
    );
    this.store.dispatch({
      type: UPDATE_MODULE,
      payload: courseModuleAssignment,
    });
    return courseModuleAssignment;
  }

  completeModule(courseEnrollId: Number, moduleId: Number): Observable<any> {
    return this.http.post(`/api/tki/catalog/courseenrollment/${courseEnrollId}/module/${moduleId}/complete`, null);
  }

  getCourseModuleTypes(): Observable<CourseModuleType[]> {
    return this.http.get<CourseModuleType[]>('/api/tki/catalog/module/types');
  }

  getWebinars(moduleId: Number): Observable<Webinar[]> {
    return this.http.get<Webinar[]>(`/api/tki/webinar/schedule/${moduleId}`);
  }

  saveWebinar(webinar: Webinar): Observable<any> {
    return this.http.post(`/api/tki/webinar`, webinar);
  }

  deleteWebinar(webinarKey: String): Observable<any> {
    return this.http.delete(`/api/tki/webinar/${webinarKey}`);
  }

  webinarEnroll(webinar: WebinarRegistration, courseEnrollId: Number): Observable<any> {
    return this.http.put(`api/tki/webinar-registration/${webinar.WebinarKey}`, {});
  }

  getEnrolledWebinars(moduleId: Number): Observable<WebinarRegistration[]> {
    return this.http.get<WebinarRegistration[]>(`/api/tki/webinar-registrations/${moduleId}`);
  }

  getTestInstance(moduleId: Number): Observable<TestQuestions> {
    return this.http.get<TestQuestions>(`/api/tki/catalog/module/test/${moduleId}`);
  }

  getTestQuestionsAnswers(moduleId: Number): Observable<QuestionAnswers[]> {
    return this.http.get<QuestionAnswers[]>(`/api/tki/catalog/module/test/${moduleId}/questions`);
  }

  submitTest(moduleId: Number, submission: any): Observable<TestResults> {
    return this.http.post<TestResults>(`/api/tki/catalog/module/test/${moduleId}/submission`, submission);
  }

  resetEnrollment(): Observable<any> {
    return this.http.delete(`/api/tki/catalog/courseenrollment`);
  }

  addTestQuestion(moduleId: Number, questions: any): Observable<any> {
    return this.http.post(`/api/tki/catalog/module/test/${moduleId}/questions`, questions);
  }

  deleteQuestion(moduleId: Number, questionId: Number): Observable<any> {
    return this.http.delete(`/api/tki/catalog/module/test/${moduleId}/questions/${questionId}`);
  }

  getCourseProgressReport(): Observable<CourseProgressReport[]> {
    return this.http.get<CourseProgressReport[]>(`/api/tki/catalog/reports/courseprogress`);
  }

  getNotifications(): Observable<KNotification[]> {
    return this.http.get<KNotification[]>(`/api/tki/catalog/enrollapprovals`);
  }

  approveCourseEnroll(approveId: Number): Observable<any> {
    return this.http.post<any>(`/api/tki/catalog/course/approve/${approveId}`, null);
  }

  getTestHistory(moduleId: Number): Observable<Test[]> {
    return this.http.get<Test[]>(`/api/tki/catalog/module/test/${moduleId}/results`);
  }

  getCompanyCredits(): Observable<Credit[]> {
    return this.http.get<Credit[]>(`/api/tki/credits`);
  }

  updateCompanyCredit(companyId: Number, credits: Number): Observable<any> {
    return this.http.put<any>(`/api/tki/companies/${companyId}/availablecredits`, { availableCredits: credits });
  }

  scheduleOneOnOne(date: Date): Observable<any> {
    return this.http.post<any>(`/api/tki/schedule-one-on-one`, { date: date });
  }
}
