import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { IAppState } from '../store';
import { Observable, interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';

@Component({
  selector: 'main-logo',
  template: 'main logo',
})
export class ModuleBreadcrumbComponent implements OnInit {
  constructor() {}

  private store: Store<IAppState>;
  ngOnInit() {}

  setStore(store: Store<IAppState>) {
    this.store = store;
  }
  breadcrumb(): Observable<any> {
    var courseModule$ = this.store.pipe(select('courseModule'));

    return courseModule$.pipe(
      switchMap((r) =>
        r.module.pipe(
          map((m) => {
            return m.ModuleName;
          }),
        ),
      ),
    );
  }
}
