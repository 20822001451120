<div fxLayoutWrap fxLayout="row" *ngIf="router.url != '/'">
    <div fxFlex="100" class="flex-p">
        <mat-card fxLayout="row" class="breadcrumb">
            <a *ngIf="router.url != '/'" routerLink="/" class="breadcrumb-item" fxLayout="row" fxLayoutAlign="start center" (click)="closeSubMenus()">
                <mat-icon>home</mat-icon>
                <span style="padding-left: 10px;" class="breadcrumb-title" i18n>Home</span>
            </a>
            <div *ngFor="let breadcrumb of breadcrumbs; let i = index;" class="breadcrumb-item" fxLayout="row" fxLayoutAlign="start center">                 
                <a [hidden]="i == (breadcrumbs.length - 1)" [routerLink]="[breadcrumb.url]">{{breadcrumb.name | async}}</a>   
                <span [hidden]="i != (breadcrumbs.length - 1)" class="breadcrumb-title active">{{breadcrumb.name | async}}</span>
            </div> 
        </mat-card>
    </div> 
</div>

