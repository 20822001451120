import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Meeting } from './company.service';
import { Responsibility } from '../perfagreement/model/responsibility';
import { GoalMember } from '../perfagreement/model/goal_member';
import { Note } from '../perfagreement/model/note';
import { User } from '../store/usersession/usersession.store';

export class EmpRatingCompare {
  public UserId: Number; //5510,
  public FirstName: String; //"John",
  public LastName: String; // "Sager",
  public EmployeeNum: Number; // "567",
  public ManUserId: Number; //60032,
  public ManFirstName: String; //"Angelo",
  public ManLastName: String; //"Bitondo",
  public PJRScore: Number; //0.8666666666666667,
  public GoalScore: Number; //0,
  public CVScore: Number; //1,
  public MeetingCount: Number; //4,
  public FinalizedCount: Number; //3
}

export class ManagerFeedback {
  public UserId: Number;
  public MeetingId: Number;
  public DateAndTime: Date;
  public FirstName: String;
  public LastName: String;
  public PrescoreFeedback: String;
  public PrescoreSuggestions: String;
  public PrescoreAdditionalTopics: String;
  public FinalizeFinalComments: String;
}

export class Company {
  public Id: Number;
  public id: Number;
  public CompanyName: string;
  public name: string;
}

export class Manager {
  public Id: Number;
  public Value: string;
}

export class Employee {
  public Id: Number;
  public FirstName: string;
  public LastName: string;
  public DepartmentId: Number;
  public IsTerminated: Boolean;
}

export class Dept {
  public Id: Number;
  public id: Number;
  public DeptName: string;
  Name: string;
  name: string;
  ParentDepartmentId: Number;
  ParentDept: Dept;
  DeptHead: User;
  DeptHeadId: Number;
  DeptFoundations: Boolean;
  CreateOn: String;
}

export class FiscalYear {
  public Id: Number;
  public CompanyId: Number;
  public StartDate: Date;
  public EndDate: Date;
  public IsClosed: Boolean;
  public IsActive: Boolean;
  public IsAlliance: Boolean;
  public CloseComments: String;
  unclosedagreements: User[];
  foundations: Foundation[];
}

export class OnlineNow {
  public UserID: Number; //5249,
  public FirstName: String; //"Wayne",
  public LastName: String; //"Nelsen",
  public CompanyName: String; //"Keyne Insight",
  public LoginTime: Date; //"2019-03-31T10:42:00.000Z",
  public LastSeenTime: Date; //"2019-03-31T16:17:00.000Z"
}

export class EmployeeActivity {
  public Id: Number; //23021,
  public FirstName: String; //"Kelly",
  public LastName: String; //"Batten",
  public IsActive: Boolean; //true,
  public LastActivityDate: Date; //"2018-11-12T17:07:00.000Z",
  public ManagerId: Number; //23016,
  public DepartmentId: Number; //13004,
  public ManagerName: String; //"Mendoza, Mark",
  public DeptName: String; //"FAB US - SALES SW REGION"
}

export class ManagerMeetingProf {
  public Id: Number; //25066,
  public FirstName: String; //"Kim",
  public LastName: String; //"Adams",
  public MeetingCount: Number; //6,
  public FinalizedCount: Number; //5,
  public MissedCount: Number; //5,
  public UpcomingCount: Number; //5,
  public CancelCount: Number; //0,
  public LastMeeting: Date; //"2018-12-20T21:00:00.000Z",
  public EmployeeCount: Number; //1
}

export class InitAccomplishment {
  public ConsultantCompanyId: Number; //1697,
  public ConsultantCompanyName: String; //"Hunter Douglas",
  public CompanyId: Number; //1702,
  public CompanyName: String; //"Demo Presentation Company (Frances Snow)",
  public fiscalYearId: Number; //1876,
  public StartDate: Date; //"2010-07-15T00:00:00.000Z",
  public EndDate: Date; //"2011-07-14T00:00:00.000Z",
  public MissedGoals: Number; //5,
  public MetGoals: Number; //11,
  public ExceededGoals: Number; //6,
  public GoalCount: Number; //11,
  public FoundationCount: Number; //5
}

export class PerfAgreement {
  public AgreementId: Number;
  public UserId: Number;
  public IsApproved: Boolean;
  public IsClosed: Boolean;
  public FirstName: String;
  public LastName: String;
  public meetings: [Meeting];
  public goals: [Goal];
  public pjr: [Responsibility];
  public pjr_msg: String;
  public meeting_msg: String;
  public goal_msg: String;
  summary: PerformanceAgreementSummary;
}

export class PerformanceAgreementSummary {
  PJRScore: Number;
  PerfFactor: Number;
  MeetingProficiencySum: Number;
  MeetingProficiency: MeetingProficiency;
  PJR: PJRSummary;
  Task: TaskSummary;
  Goal: GoalSummary;
  AccoladeConcern: AccoladeConcernSummary;
}

export class MeetingProficiency {
  MeetingCount: Number;
  FinalizedCount: Number;
  CancelCount: Number;
  MissedCount: Number;
  UpcomingCount: Number;
}
export class PJRSummary {
  ApprovedCount: Number;
  SubmittedCount: Number;
  NotSubmittedCount: Number;
}

export class TaskSummary {
  ApprovedCount: Number;
  SubmittedCount: Number;
  NotSubmittedCount: Number;
  TaskCount: Number;
  CompletedCount: Number;
  OpenCount: Number;
  PastDueCount: Number;
}

export class GoalSummary {
  TaskCount: Number;
  CompletedCount: Number;
  OpenCount: Number;
  PastDueCount: Number;
  ApprovedCount: Number;
  SubmittedCount: Number;
  NotSubmittedCount: Number;
}

export class AccoladeConcernSummary {
  AccoladeCount: Number;
  ConcernCount: Number;
  ContributionCount: Number;
  NegativeImpactCount: Number;
}

export class PerformanceAgreementChecklist {
  resp: Boolean;
  goal: Boolean;
  agreementSubmit: Boolean;
  meetingAccept: Boolean;
  managerGoalWeights: Boolean;
  managerApproveAgreement: Boolean;

  goalWeights: Boolean;
  goalsScored: Boolean;
  meetingsFinalized: Boolean;
  agreementClosed: Boolean;
}

export class PerfAgreementApproval {
  public AgreementId: Number; //88738,
  public UserId: Number; //66538,
  public IsApproved: Boolean; //false,
  public IsClosed: Boolean; //false,
  public FirstName: String; //"Carrie",
  public LastName: String; //"Babecy",
  public MeetingCount: Number; //0,
  public PJRCount: Number; //0,
  public GoalCount: Number; //0,
  public FoundationCount: Number; //4,
  public FoundationAckCount: Number; //554,
  public GoalWeight: Number; //0,
  public ManagerName: String; //"Teigen, Kim"
}

export class ReportParams {
  public Company: Company;
  public Manager: Manager;
  public User: Employee;
  public Dept: Dept;
  public fiscalYear: FiscalYear;
  public OnlyDirects: Boolean;
}

export class EmployeePerformance {
  EmpPerfSummary: EmpPerfSummary;
  EmpPerfPJR: Performance[];
  EmpPerfCV: Performance[];
  EmpPerfGoal: Performance[];
  EmpPerfMeetings: EmpPerfMeetings[];
  EmpPerfDocs: EmpPerfDocs[];
  PerfAgreementLoad: PerfAgreementLoad[];
  Goal: Goal[];
}

export class EmpPerfSummary {
  public UserName: String; // "Nikki Adams",
  public AvgPJRScore: Number; // 1,
  public AvgGoalScore: Number; //0,
  public TotalMeetings: Number; //5,
  public TotalPossible: Number; //5,
  public CancelledCount: Number; //0,
  public FinalizedCount: Number; //3,
  public NeverFinalizedCount: Number; //0,
  public ClosingComments: String; //""
}

export class Performance {
  title: String;
  meetingScores: MeetingScore[];
  finalrating: String;
  scoretypeid: Number;
}

export class MeetingScore {
  empscore: Number;
  manscore: Number;
  maninitials: String;
  finalscore: Number;
  Id: number;
  Feedback: String;
  Suggestions: String;
  Other: String;
  FinalInitComments: String;
  FinalPjrComments: String;
  FinalCoreValueComments: String;
  FinalGoalComments: String;
  PJRDisagreeComments: String;
  CoreValueDisagreeComments: String;
  GoalDisagreeComments: String;
}

export class EmpPerfCV {
  MeetingId: Number; // 468721,
  DateAndTime: Date; // 2018-03-08T13: //30: //00.000Z,
  ScoredDesc: String; // Respect Our Customers and Colleagues,
  ScoredId: Number; // 78691,
  EmpUserId: Number; // 26373,
  EmpFirstName: String; // Nikki,
  EmpLastName: String; // Adams,
  EmpScore: Number; // 1,
  ManUserId: Number; // 19533,
  ManFirstName: String; // Jenifer,
  ManLastName: String; // Burkardt,
  ManScore: Number; // 1,
  TypeId: Number; // 1
}

export class EmpPerfGoal {
  MeetingId: Number; // 468721,
  DateAndTime: Date; // 2018-03-08T13: //30: //00.000Z,
  ScoredDesc: String; // Achieve 30% PowerView activation of motorizable products and expand Hub adoption to 20%.,
  ScoredId: Number; // 256889,
  EmpUserId: Number; // 26373,
  EmpFirstName: String; // Nikki,
  EmpLastName: String; // Adams,
  EmpScore: Number; // 1,
  ManUserId: Number; // 19533,
  ManFirstName: String; // Jenifer,
  ManLastName: String; // Burkardt,
  ManScore: Number; // 1,
  TypeId: Number; // 1,
  ScoreTypeId: Number; // 1
}

export class EmpPerfMeetings {
  MeetingId: Number; // 468721,
  DateAndTime: Date; // 2018-03-08T13: //30: //00.000Z,
  ApprovalStatusId: Number; // 5,
  IsFinalized: Boolean; // true
}

export class EmpPerfDocs {
  Id: Number; //12565,
  TypeId: Number; //1,
  TypeDesc: String; //"Accolade",
  StatusId: Number; //3,
  Description: String; //"Recognizing Lori through this accolade for the rapport that she is able to build with dealers.  I've received no less than two phone calls from dealers Lori's working with only to tell me how wonderful she is to work with.  These calls are \"rare\" today.  Good job, Lori!!  Linda Mestnik",
  Factor: Number; //0,
  Corrected: Number; //0
}

export class PerfAgreementLoad {
  Id: Number; // 84184,
  PerformancePeriodId: Number; // 6046,
  UserId: Number; // 26373,
  IsApproved: Number; // 1,
  IsClosed: Number; // 0,
  ClosingComments: String; // ,
  StartDate: Date; // 2018-01-01T00: //00: //00.000Z,
  EndDate: Date; // 2018-12-31T00: //00: //00.000Z,
  FoundationCount: Number; // 4,
  FoundationAckCount: Number; // 3,
  AccoladeCount: Number; // 0,
  ConcernCount: Number; // 0,
  AddConCount: Number; // 0,
  NegCount: Number; // 0,
  PJRScore: Number; // 1,
  MgrFinalScore: Number; // 0,
  AddlContributions: Number; // 0,
  NegImpact: Number; // 0,
  MeetingProf: Number; // 80,
  PerfFactor: Number; // 0,
  TasksCount: Number; // 0,
  TasksOpen: Number; // 0,
  TasksCompleted: Number; // 0,
  TasksPastDue: Number; // 0
}

export class Goal {
  Id: Number; // 256889,
  TypeId: Number; // 1,
  GoalTypeDesc: String; // Individual Productivity,
  Title: String; // ,
  Description: String; // Achieve 30% PowerView activation of motorizable products and expand Hub adoption to 20%.,
  Benefits: String; // Leverage the PowerView Platform,
  MissedCriteria: String; // < 28% PowerView activation of motorizable and < 18% Hub adoption,
  MetCriteria: String; // Achieve 29.5% to 30.5% PowerView motorizable activation and 20% Hub adoption,
  ExceededCriteria: String; // > 31% PowerView motorizable activation and > 20% Hub Adoption,
  ApprovalStatusId: Number; // 4,
  ApprovalStatusDesc: String; // Agreed,
  ApprovalStatus: String;
  ProgressStatusId: Number; // 1,
  ProgressStatusDesc: String; // On Target,
  CreateBy: Number; // 26373,
  CreateDate: Date; // 2018-01-16T13: //05: //00.000Z,
  ApprovalDate: Date; // null,
  TargetDate: Date; // 2018-12-31T00: //00: //00.000Z,
  CompletionDate: Date; // null,
  GoalWeight: Number; // 20,
  PerformanceFactor: Number; // 0,
  PerfAgreementId: Number; // 84184,
  GoalMemberId: Number; // 337001,
  MemberTypeId: Number; // 1,
  GoalScore: Number; // 1,
  NoteCount: Number; // 0,
  StatusId: Number; // 4,
  WasApproved: Boolean; // true,
  PerformancePeriodId: Number; // 6046
  membership: GoalMember;
  goal_members: [GoalMember];
  notes: Note[];
}

export class DocumentedPerformance {
  Id: Number;
  UserId: Number;
  TypeId: Number;
  StatusId: Number;
  Title: String;
  Description: String;
  PerformancePeriodId: Number;
  Factor: Number;
  CreateBy: Number;
  CreateOn: String;
  Corrected: Number;
  EmpResponsee: String;
  TmpDescription: String;
}

export class InitProgress {
  SubInits: Number; // 0,
  EmpsWithGoal: Number; // 71,
  LinkedGoalCount: Number; // 272,
  FoundationItemId: Number; // 78907,
  Item: String; // Improve our Operating Performance,
  ParentDesc: String; // null,
  ParentId: Number; // null,
  DeptId: Number; // null,
  DeptName: String; // CAN GRP - Hunter Douglas Canada,
  GoalBehind: Number; // 4,
  GoalMeeting: Number; // 8,
  GoalExceeding: Number; // 4,
  GoalMissed: Number; // 0,
  GoalMet: Number; // 0,
  GoalExceeded: Number; // 0,
  PendingApproval: Number; // 0,
  Approved: Number; // 16,
  AvgGoalScore: Number; // 0.8181,
  PercepScore: Number; // 1.025,
  DontKnow: Number; // 6,
  DontUnderstand: Number; // 0
}

//Todo chain the FoundationItemDesc as a separate call instead of as a composite result
export class EmployeeLinkedGoal {
  Id: Number; //46459,
  UserName: String; //"Alpert-Whelan, Gwen",
  GoalCount: Number; //1
}

export class LinkedGoal {
  FoundationItemId: Number; // 78685,
  Item: String; // Deliver on the Hunter Douglas Brand Promise,
  GoalId: Number; // 261489,
  GoalDescription: String; //  QUALITY: // Ensure all products meet the fitness for purpose expectation of our customer. Achieve ME Goal of 0.46%,
  ProgressStatusId: Number; // 1,
  ProgressDescription: String; // On Target,
  TypeId: Number; // 3,
  TargetDate: Date; // 2018-12-31T00: //00: //00.000Z,
  UserId: Number; // 24300,
  FirstName: String; // Joseph,
  LastName: String; // Gutierrez,
  MemberTypeId: Number; // 1,
  MemberTypeDesc: String; // Regular,
  ApprovalStatusId: Number; // 4,
  StatusId: Number; // 1,
  OverdueTasks: Number; // 0,
  LatestScore: Number; // 1,
  IsOverdue: Boolean; // true,
  WasApproved: Boolean; // true
}

export class GoalPendingApproval {
  FoundationItemId: Number; // 75229,
  Item: String; // Deliver on the Hunter Douglas Brand Promise,
  GoalId: Number; // 251134,
  GoalDescription: String; // Develop and Execute a Broader Community Strategy for HD,
  ProgressStatusId: Number; // 1,
  ProgressDescription: String; // Past Due,
  TypeId: Number; // 3,
  TargetDate: Date; // 2017-10-01T00: //00: //00.000Z,
  UserId: Number; // 60845,
  FirstName: String; // Jamie,
  LastName: String; // Ziegler,
  MemberTypeId: Number; // 1,
  MemberTypeDesc: String; // Regular,
  ApprovalStatusId: Number; // 3,
  StatusId: Number; // 1,
  WasApproved: Boolean; // false,
  OverdueTasks: Number; // 0,
  LatestScore: Number; // null,
  IsOverdue: Boolean; // true
}

export class GoalApproved {
  FoundationItemId: Number; // 75229,
  Item: String; // Deliver on the Hunter Douglas Brand Promise,
  GoalId: Number; // 241761,
  GoalDescription: String; // Add dealer/contractors to the David Weekley and Shea Homes projects, update sales reporting to accurately identify sales performance and grow sales 6-7%,
  ProgressStatusId: Number; // 3,
  ProgressDescription: String; // Past Due,
  TypeId: Number; // 1,
  TargetDate: Date; // 2017-01-31T00: //00: //00.000Z,
  UserId: Number; // 19591,
  FirstName: String; // Larry,
  LastName: String; // Nover,
  MemberTypeId: Number; // 1,
  MemberTypeDesc: String; // Regular,
  ApprovalStatusId: Number; // 4,
  StatusId: Number; // 5,
  WasApproved: Boolean; // true,
  OverdueTasks: Number; // 0,
  LatestScore: Number; // 1,
  IsOverdue: Boolean; // true
}

export class AvgGoalRating {
  GoalId: Number; // 240602,
  UserId: Number; // 27764,
  GoalDescription: String; // Expand territory Hub adoption of PowerView orders.,
  LastScore: Number; // 2,
  TeamLeadName: String; // Chapin, Lisa,
  ManagerName: String; // Eskelsen, Todd
}

export class PerceptionScore {
  UserId: Number; //19538,
  UserName: String; //"Borowski, Debi",
  DateAndTime: Date; //"2018-11-13T20:00:00.000Z",
  Score: Number; //1
}

export class GoalSub {
  FoundationItemId: Number; // 78685,
  Item: String; // Deliver on the Hunter Douglas Brand Promise,
  GoalId: Number; // 259681,
  GoalDescription: String; // \nAccuracy - Order Error Rate (error/orders) .01 - .15,
  ProgressStatusId: Number; // 1,
  ProgressDescription: String; // On Target,
  TypeId: Number; // 1,
  TargetDate: Date; // 2018-12-28T00: //00: //00.000Z,
  UserId: Number; // 49602,
  FirstName: String; // Kyle,
  LastName: String; // Rea,
  MemberTypeId: Number; // 1,
  MemberTypeDesc: String; // Regular,
  ApprovalStatusId: Number; // 4,
  StatusId: Number; // 1,
  WasApproved: Boolean; // true,
  OverdueTasks: Number; // 0,
  LatestScore: Number; // 2,
  IsOverdue: Boolean; // true
}

export class Initiative {
  initid: Number;
  parentid: Number;
  type: String; //goals[i].DeptName ? 'dept':'company',
  deptname: String; //goals[i].DeptName,
  deptid: Number; //goals[i].DeptId,
  name: String; //goals[i].Item,
  goals: InitiativeGoal[];
  childinits: Initiative[];
  hidden: Boolean;
}

export class InitiativeGoal {
  FoundationItemId: Number; // 79247,
  ParentFoundationId: Number; // null,
  Item: String; // Sustain Bookings Growth to Support 15% plus Shipping growth annually,
  GoalId: Number; // null,
  GoalDescription: String; // null,
  ProgressStatusId: Number; // null,
  ProgressDescription: String; // null,
  TypeId: Number; // null,
  TargetDate: Date; // null,
  UserId: Number; // null,
  FirstName: String; // null,
  LastName: String; // null,
  MemberTypeId: Number; // null,
  MemberTypeDesc: String; // null,
  ApprovalStatusId: Number; // null,
  StatusId: Number; // null,
  WasApproved: Boolean; // null,
  DeptName: String; // null,
  DeptId: Number;
  UserDeptId: Number;
  SortOrder: Number; // null,
  OverdueTasks: Number; // null,
  LatestScore: Number; // null,
  IsOverdue: Boolean; // null,
}

export class Foundation {
  Id: Number;
  Description: string;
  foundation_type: FoundationType;
  items: FoundationItem[];
  IsPublished: Boolean;
  PublishDate: String;
}

export class FoundationType {
  Id: Number;
  Description: String;
}

export class FoundationItem {
  Id: Number;
  Item: string;
  Definition: string;
}

export class Prescore {
  initiatives: Foundation[];
  cb: Foundation[];
  pjr: Responsibility[];
  goals: Goal[];
  name: String;
}

export class Finalize {
  initiatives: Foundation[];
  cb: Foundation[];
  pjr: Responsibility[];
  goals: Goal[];
  ms_man: MeetingScore;
  ms_emp: MeetingScore;
  init_scores_emp: MeetingInitiativeScore[];
  pjr_scores_emp: MeetingPrimaryJobResponsibilityScore[];
  cb_scores_emp: MeetingFoundationScore[];
  goal_scores_emp: MeetingGoalScore[];
  init_scores_man: MeetingInitiativeScore[];
  pjr_scores_man: MeetingPrimaryJobResponsibilityScore[];
  cb_scores_man: MeetingFoundationScore[];
  goal_scores_man: MeetingGoalScore[];
}

export class MeetingInitiativeScore {
  Id: number;
  MeetingScoreId: number;
  FoundationId: number;
  Score: number;
}

export class MeetingPrimaryJobResponsibilityScore {
  Id: number;
  MeetingScoreId: number;
  PrimaryJobResponsibilityId: number;
  Score: number;
}

export class MeetingFoundationScore {
  Id: number;
  MeetingScoreId: number;
  FoundationId: number;
  Score: number;
}

export class MeetingGoalScore {
  Id: number;
  MeetingScoreId: number;
  GoalId: number;
  Score: number;
}

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  public reportParams: ReportParams;

  constructor(private http: HttpClient) {}

  getReportParams(): ReportParams {
    if (this.reportParams == null) {
      this.reportParams = JSON.parse(localStorage.getItem('ReportParams'));
      if (this.reportParams == null) {
        this.reportParams = new ReportParams();
      }
    }
    return this.reportParams;
  }

  setReportParamsCompany(company: Company) {
    this.reportParams.Company = company;
    localStorage.setItem('ReportParams', JSON.stringify(this.reportParams));
  }

  setReportParamsUser(user: Employee) {
    this.reportParams.User = user;
    localStorage.setItem('ReportParams', JSON.stringify(this.reportParams));
  }

  setReportParamsManager(manager: Manager) {
    this.reportParams.Manager = manager;
    localStorage.setItem('ReportParams', JSON.stringify(this.reportParams));
  }

  setReportParamsfiscalYear(fiscalYear: FiscalYear) {
    this.reportParams.fiscalYear = fiscalYear;
    localStorage.setItem('ReportParams', JSON.stringify(this.reportParams));
  }

  setReportParamsDept(dept: Dept) {
    this.reportParams.Dept = dept;
    localStorage.setItem('ReportParams', JSON.stringify(this.reportParams));
  }

  setReportParamsOnlyDirects(onlyDirects: Boolean) {
    this.reportParams.OnlyDirects = onlyDirects;
    localStorage.setItem('ReportParams', JSON.stringify(this.reportParams));
  }

  getEmpRatingCompare(companyid: Number, deptid: Number, fiscalYearid: Number): Observable<EmpRatingCompare[]> {
    let params = new HttpParams();
    params = params.append('companyid', String(companyid));
    params = params.append('deptid', String(deptid));
    params = params.append('fiscalYearid', String(fiscalYearid));

    return this.http.get<EmpRatingCompare[]>('/api/reports/EmpRatingCompare', { params });
  }

  getGoalsByInitiative(companyid: Number, deptid: Number, fiscalYearid: Number): Observable<Initiative[]> {
    let params = new HttpParams();
    params = params.append('companyid', String(companyid));
    params = params.append('deptid', String(deptid));
    params = params.append('fiscalYearid', String(fiscalYearid));

    return this.http.get<Initiative[]>('/api/reports/GoalsByInit', { params });
  }

  getOnlineNow(): Observable<OnlineNow[]> {
    return this.http.get<OnlineNow[]>('/api/reports/OnlineNow');
  }

  getEmployeeActivity(companyid: Number): Observable<EmployeeActivity[]> {
    let params = new HttpParams();
    params = params.append('companyid', String(companyid));

    return this.http.get<EmployeeActivity[]>('/api/reports/EmployeeActivity', { params });
  }

  getManagerMeetingProf(
    companyid: Number,
    deptid: Number,
    fiscalYearid: Number,
    onlymydirects: Boolean,
  ): Observable<ManagerMeetingProf[]> {
    let params = new HttpParams();

    params = params.append('companyid', String(companyid));
    params = params.append('deptid', String(deptid));
    params = params.append('fiscalYearid', String(fiscalYearid));
    params = params.append('onlymydirects', String(onlymydirects ? 1 : 0));

    return this.http.get<ManagerMeetingProf[]>('/api/reports/ManagerMeetingProf', { params });
  }

  getManagerFeedback(companyid: Number, managerid: Number, fiscalYearid: Number): Observable<ManagerFeedback[]> {
    let params = new HttpParams();
    params = params.append('userid', String(managerid));
    params = params.append('fiscalYearid', String(fiscalYearid));

    return this.http.get<ManagerFeedback[]>('/api/reports/ManagerFeedback', { params });
  }

  getPerformanceAgreement(
    companyid: Number,
    managerid: Number,
    fiscalYearid: Number,
    deptid: Number,
  ): Observable<PerfAgreement[]> {
    let params = new HttpParams();
    params = params.append('managerid', String(managerid));
    params = params.append('fiscalYearid', String(fiscalYearid));
    params = params.append('companyid', String(companyid));
    params = params.append('deptid', String(deptid));

    return this.http.get<PerfAgreement[]>('/api/reports/PerfAgreeStatus', { params });
  }

  getPerformanceAgreementApproval(
    companyid: Number,
    managerid: Number,
    fiscalYearid: Number,
    deptid: Number,
  ): Observable<PerfAgreementApproval[]> {
    let params = new HttpParams();
    params = params.append('managerid', String(managerid));
    params = params.append('fiscalYearid', String(fiscalYearid));
    params = params.append('companyid', String(companyid));
    params = params.append('deptid', String(deptid));

    return this.http.get<PerfAgreementApproval[]>('/api/reports/PerfAgreeStatusApproval', { params });
  }

  getInitAccomplishment(companyid: Number): Observable<InitAccomplishment[]> {
    let params = new HttpParams();
    params = params.append('companyid', String(companyid));

    return this.http.get<InitAccomplishment[]>('/api/reports/InitAccomplishment', { params });
  }

  getEmployeePerformance(fiscalYearid: Number, userid: Number): Observable<EmployeePerformance> {
    let params = new HttpParams();
    params = params.append('userid', String(userid));
    params = params.append('fiscalYearid', String(fiscalYearid));

    return this.http.get<EmployeePerformance>('/api/reports/EmpPerf', { params });
  }

  getInitProgress(companyid: Number, deptid: Number, fiscalYearid: Number): Observable<InitProgress[]> {
    let params = new HttpParams();
    params = params.append('companyid', String(companyid));
    params = params.append('deptid', String(deptid));
    params = params.append('fiscalYearid', String(fiscalYearid));

    return this.http.get<InitProgress[]>('/api/reports/InitProgress', { params });
  }

  getEmployeeLinkedGoals(foundationitemid: Number): Observable<EmployeeLinkedGoal[]> {
    let params = new HttpParams();
    params = params.append('foundationitemid', String(foundationitemid));

    return this.http.get<EmployeeLinkedGoal[]>('/api/reports/LinkedEmp', { params });
  }

  getLinkedGoals(foundationitemid: Number): Observable<Initiative> {
    let params = new HttpParams();
    params = params.append('foundationitemid', String(foundationitemid));

    return this.http.get<Initiative>('/api/reports/GoalsLinked', { params });
  }

  getGoalsPendingApproval(foundationitemid: Number): Observable<Initiative> {
    let params = new HttpParams();
    params = params.append('foundationitemid', String(foundationitemid));

    return this.http.get<Initiative>('/api/reports/GoalNotApproved', { params });
  }

  getGoalsApproved(foundationitemid: Number): Observable<Initiative> {
    let params = new HttpParams();
    params = params.append('foundationitemid', String(foundationitemid));

    return this.http.get<Initiative>('/api/reports/GoalApproved', { params });
  }

  getAverageGoalRating(foundationitemid: Number): Observable<AvgGoalRating[]> {
    //TODO cleanup company id requirement
    var companyid;
    if (!this.reportParams.Company) {
      companyid = 1697;
    } else {
      companyid = this.reportParams.Company.Id;
    }

    let params = new HttpParams();
    params = params.append('foundationitemid', String(foundationitemid));
    params = params.append('companyid', String(companyid));

    return this.http.get<AvgGoalRating[]>('/api/reports/GoalAvg', { params });
  }

  getPerceptionScores(foundationitemid: Number): Observable<PerceptionScore[]> {
    let params = new HttpParams();
    params = params.append('foundationitemid', String(foundationitemid));

    return this.http.get<PerceptionScore[]>('/api/reports/Precep', { params });
  }

  getGoalSubs(
    foundationitemid: Number,
    companyid: Number,
    fiscalYearid: Number,
    goaltype: Number,
    goalstatus: Number,
  ): Observable<Initiative> {
    let params = new HttpParams();
    params = params.append('foundationitemid', String(foundationitemid));
    params = params.append('fiscalYearid', String(fiscalYearid));
    params = params.append('companyid', String(companyid));
    params = params.append('goaltype', String(goaltype));
    params = params.append('goalstatus', String(goalstatus));

    return this.http.get<Initiative>('/api/reports/SubGoal', { params });
  }

  getCompanies(): Observable<Company[]> {
    return this.http.get<Company[]>('/api/reports/companies');
  }

  getManagers(companyid: Number): Observable<Manager[]> {
    let params = new HttpParams();
    params = params.append('companyid', String(companyid));

    return this.http.get<Manager[]>('/api/reports/managers', { params });
  }

  getEmployees(companyid: Number): Observable<Employee[]> {
    let params = new HttpParams();
    params = params.append('companyid', String(companyid));

    return this.http.get<Employee[]>('/api/reports/users', { params });
  }

  getDepartments(companyid: Number): Observable<Dept[]> {
    let params = new HttpParams();
    params = params.append('companyid', String(companyid));

    return this.http.get<Dept[]>('/api/reports/departments', { params });
  }

  getPerformancePeriods(companyid: Number): Observable<FiscalYear[]> {
    let params = new HttpParams();
    params = params.append('companyid', String(companyid));

    return this.http.get<FiscalYear[]>('/api/reports/performanceperiods', { params });
  }
}
