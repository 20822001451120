import { Component, ViewChild } from '@angular/core';
import { UserService } from '../../services/user.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-logout',
  template: ``
})
export class LogoutComponent {
  @ViewChild('logoutForm', {static: false}) logoutForm;
  keyneWeb = environment.keyneWeb;

  constructor(public userService: UserService) {

  
  }

  ngAfterViewInit() {
    this.userService.logout().subscribe( 
      data => {
        window.location.href = environment.keyneWeb + '/k3/Account/LogOff';
    },
    error => {
      window.location.href = environment.keyneWeb + '/k3/Account/LogOff';
    }

    );
  }
}
