import { Component, OnInit } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { FiscalYear } from '../services/reports.service';
import { AuthState, RefreshState, SEND_REFRESH, UPDATE_FISCAL_YEAR } from '../store/usersession/usersession.store';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { IAppState } from '../store';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppSettings } from '../app.settings';

const GET_fiscalYears = gql`
  query getfiscalYear($company_id: Int!) {
    fiscal_years(company_id: $company_id) {
      Id
      StartDate
      EndDate
      IsClosed
      foundations {
        Id
        Description
        items {
          Id
          Item
          Definition
        }
        foundation_type {
          Id
          Description
        }
      }
    }
  }
`;

@Component({
  selector: 'app-fiscalyear',
  templateUrl: './fiscalyear.component.html',
  styleUrls: ['./fiscalyear.component.scss'],
})
export class FiscalyearComponent implements OnInit {
  user$: Observable<AuthState>;
  company_id: Number;

  loading: Boolean = true;
  public fiscalYears: FiscalYear[];
  public selectedOption: FiscalYear;

  refreshSub: Subscription;
  refresh$: Observable<RefreshState>;

  constructor(
    private apollo: Apollo,
    public store: Store<IAppState>,
    private router: Router,
    public appSettings: AppSettings,
  ) {
    this.user$ = this.store.pipe(select('auth'));
  }

  ngOnInit(): void {
    this.refresh$ = this.store.pipe(select('refresh'));

    this.user$.subscribe((evt) => {
      this.company_id = evt.CurrentUser.CompanyID;

      this.refresh();

      this.refreshSub = this.refresh$.subscribe((data) => {
        if (data.fiscalyear !== false) {
          this.refresh();
        }
      });
    });
  }

  compareFiscalyearObjects(object1: any, object2: any) {
    // console.log("comparing", object1 && object2 && object1.id == object2.id)
    return object1 && object2 && object1.Id == object2.Id;
  }

  refresh() {
    this.getFiscalYears();
  }

  ngOnDestroy() {
    this.refreshSub.unsubscribe();
  }

  onChange(evt) {
    if (this.selectedOption.IsClosed) {
      this.appSettings.settings.theme = 'blue-dark';
    } else {
      this.appSettings.settings.theme = 'teal-light';
    }

    this.store.dispatch({
      type: UPDATE_FISCAL_YEAR,
      payload: this.selectedOption,
    });

    this.store.dispatch({
      type: SEND_REFRESH,
      payload: false,
    });

    // this.router.routeReuseStrategy.shouldReuseRoute = function(){return false;};

    // let currentUrl = this.router.url + '?';

    // this.router.navigateByUrl(currentUrl)
    //   .then(() => {
    //     this.router.navigated = false;
    //     this.router.navigate([this.router.url]);
    //   });
  }

  public getFiscalYears() {
    this.loading = true;
    // const fiscalYear$ = this.store.pipe(select('fiscalYear'));

    // fiscalYear$.subscribe((evt) => {
    //   console.log('fiscal year event', evt.fiscal_year);
    //   // this.company_id = evt.CurrentUser.CompanyID

    // TODO caching issue where foundations go into localstorage
    this.apollo
      .query<any>({
        query: GET_fiscalYears,
        variables: { company_id: this.company_id },
        fetchPolicy: 'network-only',
      })
      .subscribe(({ data, loading }) => {
        this.fiscalYears = data.fiscal_years;

        if (data.fiscal_years.length === 0) {
          this.selectedOption = null;
          this.store.dispatch({
            type: UPDATE_FISCAL_YEAR,
            payload: null,
          });
          this.loading = false;
          return;
        }
        const sorted = this.fiscalYears.slice().sort((a, b) => (a.Id < b.Id ? 1 : -1));
        this.fiscalYears = sorted;
        // console.log("fiscalYear", evt.fiscal_year)

        // if (evt.fiscal_year) {
        //   // console.log('evt:', evt.fiscal_year);
        //   for (const year of this.fiscalYears) {
        //     // console.log('compare', year.Id, evt.fiscal_year.Id, year.Id === evt.fiscal_year.Id);
        //     if (year.Id === evt.fiscal_year.Id) {
        //       console.log('blah');
        //       this.selectedOption = year;

        //       // this.store.dispatch({
        //       //   type: UPDATE_FISCAL_YEAR,
        //       //   payload: this.selectedOption,
        //       // });

        //       if (this.selectedOption.IsClosed) {
        //         this.appSettings.settings.theme = 'blue-dark';
        //       } else {
        //         this.appSettings.settings.theme = 'teal-light';
        //       }
        //     }
        //   }
        // }

        if (this.fiscalYears?.find((x) => x.Id === this.selectedOption?.Id) === undefined || !this.selectedOption) {
          this.selectedOption = this.fiscalYears[0];
          if (this.fiscalYears[0].IsClosed) {
            this.appSettings.settings.theme = 'blue-dark';
          } else {
            this.appSettings.settings.theme = 'teal-light';
          }

          this.store.dispatch({
            type: UPDATE_FISCAL_YEAR,
            payload: this.selectedOption,
          });
        }
        this.loading = false;
      });
    // });
  }
}
