export class KNotify {
  constructor(
    public type: string,
    public subject: string,
    public message: string,
    public createdAt: Date,
    public sync: Boolean,
  ) {
    this.type = type;
    this.subject = subject;
    this.message = message;
    this.createdAt = createdAt;
    this.sync = sync;
  }
}
