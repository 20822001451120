import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { UserService } from './user.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (this.userService.isLoggedIn(null)) {
      return true;
    } else if (this.router.url === '/login') {
      return true;
    } else {
      return this.userService.loginK3Session().pipe(
        map((auth: Boolean) => {
          if (auth) {
            return true;
          }
          document.location.href = environment.keyneWeb + '/k3/Content/Dashboard.html';
          return false;
        }),
      );

      // this.router.navigate(['/checksession'], {
      //   queryParams: {
      //     return: state.url,
      //   },
      // });
      // return false;
    }
  }
}
