import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { PagesComponent } from './pages/pages.component';
import { BlankComponent } from './pages/blank/blank.component';
import { SearchComponent } from './pages/search/search.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { ErrorComponent } from './pages/errors/error/error.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { AuthGuardService } from './services/authguard.service';

export const dashboard = $localize`:@@dashboard:Dashboard`;
export const users = $localize`:@@users:Users`;
export const institute = $localize`:@@institute:Institute`;
export const instituteadmin = $localize`:@@instituteadmin:Institute Admin`;
export const reports = $localize`:@@reports:Reports`;
export const profile = $localize`:@@profile:Profile`;
export const companies = $localize`:@@profile:Companies`;
export const admin = $localize`:@@profile:Admin`;
export const company_settings = $localize`:@@profile:Company Admin`;
export const myteam = $localize`:@@meetings:My Team`;
export const error = $localize`:@@error:Error`;
export const perfagreement = $localize`:@@profile:Performance Agreement`;
export const team = $localize`:@@profile:Team`;

export const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuardService],
    component: PagesComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('../app/pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
        data: { breadcrumb: dashboard },
      },
      {
        path: 'institute',
        loadChildren: () => import('../app/institute/institute.module').then((m) => m.InstituteModule),
        data: { breadcrumb: institute },
      },
      {
        path: 'instituteadmin',
        loadChildren: () => import('../app/instituteadmin/instituteadmin.module').then((m) => m.InstituteAdminModule),
        data: { breadcrumb: admin },
      },
      {
        path: 'admin',
        loadChildren: () => import('../app/admin/admin.module').then((m) => m.AdminModule),
        data: { breadcrumb: companies },
      },
      {
        path: 'company',
        loadChildren: () => import('../app/company/company.module').then((m) => m.CompanyModule),
        data: { breadcrumb: company_settings },
      },
      {
        path: 'perfagreement',
        loadChildren: () => import('../app/perfagreement/perfagreement.module').then((m) => m.PerfagreementModule),
        data: { breadcrumb: '' },
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('../app/institutereports/institutereports.module').then((m) => m.InstituteReportsModule),
        data: { breadcrumb: reports },
      },
      {
        path: 'profile',
        loadChildren: () => import('../app/profile/profile.module').then((m) => m.ProfileModule),
        data: { breadcrumb: profile },
      },
      {
        path: 'myteam',
        loadChildren: () => import('../app/meetings/meetings.module').then((m) => m.MeetingsModule),
        data: { breadcrumb: myteam },
      },
    ],
  },
  { path: 'login', loadChildren: () => import('../app/pages/login/login.module').then((m) => m.LoginModule) },
  {
    path: 'checksession',
    loadChildren: () => import('../app/login/loginsession.module').then((m) => m.LoginSessionModule),
  },
  { path: 'logout', component: LogoutComponent },
  {
    path: 'register',
    loadChildren: () => import('../app/pages/register/register.module').then((m) => m.RegisterModule),
  },
  { path: 'error', component: ErrorComponent, data: { breadcrumb: error } },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class Routing {}

// export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes, {
//     preloadingStrategy: PreloadAllModules,
//     relativeLinkResolution: 'legacy'
// });
