import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { Routing } from './app.routing';
// import { store, instrumentation } from './store';
import { SharedModule } from './shared/shared.module';
import { UserService } from './services/user.service';
import { reducers, metaReducers } from './store';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
} from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  suppressScrollX: true,
};
// import {WebStorageModule, LocalStorageService} from 'angular2-localstorage';

import { environment } from '../environments/environment';
import { PagesComponent } from './pages/pages.component';
import { BlankComponent } from './pages/blank/blank.component';
import { SearchComponent } from './pages/search/search.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { ErrorComponent } from './pages/errors/error/error.component';
import { SidenavComponent } from './theme/components/sidenav/sidenav.component';
import { VerticalMenuComponent } from './theme/components/menu/vertical-menu/vertical-menu.component';
import { HorizontalMenuComponent } from './theme/components/menu/horizontal-menu/horizontal-menu.component';
import { BreadcrumbComponent } from './theme/components/breadcrumb/breadcrumb.component';
import { FlagsMenuComponent } from './theme/components/flags-menu/flags-menu.component';
import { FullScreenComponent } from './theme/components/fullscreen/fullscreen.component';
import { ApplicationsComponent } from './theme/components/applications/applications.component';
import { MessagesComponent } from './theme/components/messages/messages.component';
import { UserMenuComponent } from './theme/components/user-menu/user-menu.component';
import { AppSettings } from './app.settings';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { PipesModule } from './theme/pipes/pipes.module';
import { OverlayContainer } from '@angular/cdk/overlay';
import { CustomOverlayContainer } from './theme/utils/custom-overlay-container';
import { LogoutComponent } from './pages/logout/logout.component';
import { AuthGuardService } from './services/authguard.service';
import { CustomHttpInterceptor } from './services/http.interceptor';
import { InstituteComponent } from './institute/institute.component';
import { InstituteModule } from './institute/institute.module';
import { InstituteService } from './services/institute.service';
import { BreadcrumbsModule } from './breadcrumbs/breadcrumbs.module';
import { HighchartsChartModule } from 'highcharts-angular';
import { ReportService } from './services/reports.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { TranslationComponent } from './translation/translation.component';
import { BaseUrlInterceptor } from './services/baseurl.interceptor';
import { GraphQLModule } from './graphql.module';
import { FiscalyearComponent } from './fiscalyear/fiscalyear.component';
import { PusherService } from './services/pusher.service';
import { ImpersonateComponent } from './admin/impersonate/impersonate.component';

registerLocaleData(localeEs);

@NgModule({
  declarations: [
    AppComponent,
    PagesComponent,
    BlankComponent,
    SearchComponent,
    NotFoundComponent,
    ErrorComponent,
    SidenavComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    BreadcrumbComponent,
    FlagsMenuComponent,
    FullScreenComponent,
    ApplicationsComponent,
    MessagesComponent,
    UserMenuComponent,
    LogoutComponent,
    FiscalyearComponent,
    ImpersonateComponent,
    TranslationComponent,
  ],
  imports: [
    BrowserModule,
    SharedModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    // store,
    Routing,
    ReactiveFormsModule,
    HighchartsChartModule,
    FontAwesomeModule,
    PerfectScrollbarModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    PipesModule,
    BreadcrumbsModule,
    GraphQLModule,
    // instrumentation
  ],
  exports: [],
  //  exports: [MdButtonModule, MdCheckboxModule],
  providers: [
    UserService,
    ReportService,
    AuthGuardService,
    PusherService,
    HttpClientModule,
    AppSettings,
    InstituteService,
    { provide: 'BASE_API_URL', useValue: environment.apiUrl },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseUrlInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'en' },
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class AppModule {}
