import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CourseBreadcrumbComponent } from './CourseBreadcrumbComponent';
import { ModuleBreadcrumbComponent } from './ModuleBreadcrumbComponent';
import { CompanyBreadcrumbComponent } from './CompanyBreadcrumpComponent';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [CourseBreadcrumbComponent, ModuleBreadcrumbComponent, CompanyBreadcrumbComponent],
  declarations: [CourseBreadcrumbComponent, ModuleBreadcrumbComponent, CompanyBreadcrumbComponent]
})
export class BreadcrumbsModule { }
