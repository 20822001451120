import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { MenuService } from '../menu/menu.service';
import { IAppState } from '../../../store';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthState, User, NotificationState } from '../../../store/usersession/usersession.store';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MenuService],
})
export class SidenavComponent implements OnInit {
  public menuItems: Array<any>;
  public settings: Settings;
  user$: Observable<AuthState>;
  notify$: Observable<NotificationState>;

  keyneWeb = environment.keyneWeb;

  constructor(public appSettings: AppSettings, public menuService: MenuService, public store: Store<IAppState>) {
    this.settings = this.appSettings.settings;
    this.user$ = this.store.pipe(select('auth'));
    this.notify$ = this.store.pipe(select('notify'));
  }

  ngOnInit() {
    this.user$.subscribe((value) => {
      let rolesarray = value.CurrentUser.Roles;
      let verticalMenuItems = this.menuService.getVerticalMenuItems();

      let menu2 = Object.assign([], verticalMenuItems);
      for (let i = 0; i < menu2.length; i++) {
        let authorized = false;
        if (menu2[i].requiredRoles.length > 0) {
          for (let j = 0; j < rolesarray.length; j++) {
            if (menu2[i].requiredRoles.indexOf(rolesarray[j]) != -1) {
              // console.log("authorized");
              authorized = true;
              break;
            }
          }
          if (!authorized) {
            menu2.splice(i, 1);
            i = 0;
          }
        }
      }
      this.menuItems = menu2;
    });

    // this.user$.subscribe(evt => {
    //   this.title = evt.CurrentUser.Title;
    //   this.name = evt.CurrentUser.FirstName + ' ' + evt.CurrentUser.LastName;
    //   this.userImage = evt.CurrentUser.Icon;

    // });
  }

  public closeSubMenus() {
    let menu = document.querySelector('.sidenav-menu-outer');
    if (menu) {
      for (let i = 0; i < menu.children[0].children.length; i++) {
        let child = menu.children[0].children[i];
        if (child) {
          if (child.children[0].classList.contains('expanded')) {
            child.children[0].classList.remove('expanded');
            child.children[1].classList.remove('show');
          }
        }
      }
    }
  }
}
