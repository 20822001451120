
Vertical Menu

<span i18n="@@credit">Credit Management</span>
<span i18n="@@institute">Institute</span>
<span i18n="@@reports">Reports</span>
<span i18n="@@meetings">Meetings</span>
<span i18n="@@admin">Admin</span>
<span i18n="@@courses">Courses</span>
<span i18n="@@users">Users</span>
<span i18n="@@companies">Companies</span>

app.routing.ts

<span i18n="@@dashboard">Dashboard</span>
<span i18n="@@users">Users</span>
<span i18n="@@institute">Institute</span>
<span i18n="@@instituteadmin">Institute Admin</span>
<span i18n="@@reports">Reports</span>
<span i18n="@@profile">Profile</span>
<span i18n="@@meetings">Meetings</span>
<span i18n="@@error">Error</span>

institute.routing.ts 

<span i18n="@@notification">Notifications</span>

instituteadmin.routing.ts 

<span i18n="@@courses">Courses</span>
<span i18n="@@addcourse">Add Course</span>
<span i18n="@@credit">Credit Management</span>
<span i18n="@@editcourse">Edit Course</span>
<span i18n="@@coursemodules">Course Modules</span>
<span i18n="@@addmodule">Add Module</span>
<span i18n="@@editmodule">Edit Module</span>
<span i18n="@@modulewebinars">Module Webinars</span>
<span i18n="@@edittest">Edit Test</span>

insitutereports.routing.ts 

<span i18n="@@courseprogress">Course Progress</span>
<span i18n="@@enrollments">Enrollments</span>
<span i18n="@@performanceagreement">Performance Agreement</span>
<span i18n="@@performanceagreementdetail">Performance Agreement Detail</span>
<span i18n="@@goalsbyinitiative">Goals by Initiative</span>
<span i18n="@@employeeperformance">Employee Performance</span>
<span i18n="@@feedbacktomanager">Feedback to Manager</span>
<span i18n="@@empratingcomp">Employee Rating Comparison</span>
<span i18n="@@managermeetingprof">Manager Meeting Proficiency</span>
<span i18n="@@employeelogin">Employee Login</span>
<span i18n="@@onlinenow">Online Now</span>
<span i18n="@@initaccomplishments">Initiative Accomplishments</span>
<span i18n="@@initprogress">Initiative Progress</span>
<span i18n="@@emplinked">Employee Linked Goals</span>
<span i18n="@@linkedgoals">Linked Goals</span>
<span i18n="@@goalspendingapproval">Goals Pending Approval</span>
<span i18n="@@goalsapproved">Goals Approved</span>
<span i18n="@@avggoalrating">Average Goal Rating</span>
<span i18n="@@perceptionscore">Perception Score</span>
<span i18n="@@goalsub">Goal</span>

