import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import Pusher from 'pusher-js';
import { KNotify } from './notification';
import { Apollo, gql } from 'apollo-angular';
import { take } from 'rxjs/operators';
import { IAppState } from '../store';
import { AuthState, SEND_REFRESH, UPDATE_NOTIFY_COUNT } from '../store/usersession/usersession.store';

const GET_APPROVALS = gql`
  query {
    approvals {
      id
      type
      desc
      user
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class PusherService {
  private subject: Subject<KNotify> = new Subject<KNotify>();

  user$: Observable<AuthState>;

  private pusherClient: Pusher;

  constructor(private store: Store<IAppState>, private apollo: Apollo) {
    this.user$ = this.store.pipe(select('auth'));

    this.user$.subscribe((evt) => {
      this.setupChannel(evt);
    });
  }

  async setupChannel(user) {
    if (!user) return;
    this.pusherClient = new Pusher('941a52df4e0e8ee3b440', { cluster: 'us2' });

    const channel = this.pusherClient.subscribe('general-' + user.CurrentUser.UserID);

    channel.bind('notify', (data: { type: string; subject: string; message: string; time: string; sync: string }) => {
      const notify = new KNotify(data.type, data.subject, data.message, new Date(data.time), data.sync === 'true');

      if (notify.sync) {
        this.syncNotifyItems();
      }

      this.subject.next(notify);
    });

    channel.bind('refresh', (data: { type: string; subject: string }) => {
      const notify = new KNotify(data.type, data.subject, '', new Date(), false);
      this.subject.next(notify);

      this.store.dispatch({
        type: SEND_REFRESH,
      });
    });
  }

  getNotifyItems(): Observable<KNotify> {
    return this.subject.asObservable();
  }

  syncNotifyItems() {
    this.apollo.query<any>({ query: GET_APPROVALS, fetchPolicy: 'network-only' }).subscribe(({ data, loading }) => {
      this.store.dispatch({
        type: UPDATE_NOTIFY_COUNT,
        payload: data.approvals.length,
      });
    });
  }
}
