import { ActionReducer, Action as NgRxAction } from '@ngrx/store';
import { Course, CourseModule, CourseModuleAssignment } from '../../services/institute.service';
import { of, Observable } from 'rxjs';
import { Dept, FiscalYear } from '../../services/reports.service';
import { Meeting } from '../../services/company.service';

export interface Action extends NgRxAction {
  payload?: any;
}

export class User {
  Id: number;
  FirstName: string;
  LastName: string;
  FullName: string;
  manager: User;
  Title: string;
  Language: string;
  EmployeeNum: string;
  company: Company;
  dept: Dept;
  ManagerId: Number;
  CompanyId: Number;
  DepartmentId: Number;
  email: string;
  UserID: number;
  EmailAddress: String;
  Password: String;
  CompanyID: Number;
  DepartmentID: Number;
  ManagerID: Number;
  Icon: String;
  ApprovalStatusID: Number;
  Roles: [String];
  roles: [Role];
  team_members: [User];
  partners: [User];
  extra_team_members: [User];
  manager_meetings: [Meeting];
  // Company: Company = null;
  displayName!: string;
  avatar!: string;
  timeZone!: string;
  lastSeen: number;
  IsActive: Boolean;
  IsTerminated: Boolean;
  IsExempt: Boolean;
}
export class UserUtils {
  static hasRole(UserRoles: string, role: string) {
    if (UserRoles.indexOf(role + '|') !== -1) {
      return true;
    }
    return false;
  }

  // Pass in a pipe delimited list of roles, returns true if the user has one of those roles
  static hasAnyRole(UserRoles: string, role: string) {
    const rolez = role.split('|');
    for (let i = 0; i < rolez.length; i++) {
      if (UserUtils.hasRole(UserRoles, rolez[i])) {
        return true;
      }
    }
    return false;
  }
}

export class Role {
  RoleName: string;
}
export class Company {
  Id: number;
  id: number;
  Name: string;
  StreetAddress1: String;
  StreetAddress2: String;
  City: String;
  State: String;
  ZipCode: String;
  Country: String;
  Phone: String;
  SeniorExecFirstName: String;
  SeniorExecLastName: String;
  SeniorExecTitle: String;
  SeniorExecEmail: String;
  ContactName: String;
  ContactPhone: String;
  ContactEmail: String;
  SeniorExec: User;
  SeniorExecId: Number;
  IsActive: boolean;
  IsDemo: boolean;
  CreateDate: String;
  name: String;
  users: User[];
  depts: Dept[];
  consultant: User;
  ConsultantName: String;
  consultants: User[];
  consultants_ids: Number[];
  ActiveUserCount: Number;
  TotalUserCount: Number;
  TimeZone: Number;
  RequestedLicenses: Number;
  LicType: Number;
  IsConsulting: boolean;
  IsPartnerShip: boolean;
  ApprovalStatusID: Number;
  CompanyTypeID: Number;
  RenewalDate: Date;
  LicenseCount: Number;
  CreditCount: Number;
  Icon: String;
}

export interface NotificationState {
  notifyCount: Number;
}

export interface AuthState {
  CurrentUser: User;
  OrigUser: User;
  Impersonations: any[];
}

export interface CourseModuleState {
  course: Observable<Course>;
  module: Observable<CourseModuleAssignment>;
}

export interface CompanyState {
  company: Observable<Company>;
}

export interface FiscalYearState {
  fiscal_year: FiscalYear;
}

export interface RefreshState {
  fiscalyear?: boolean;
}

const initialState: AuthState = {
  CurrentUser: new User(),
  OrigUser: new User(),
  Impersonations: impersonationStorage(),
};

const initialNotifyState: NotificationState = {
  notifyCount: new Number(0),
};

const initialCourseModuleState: CourseModuleState = {
  course: null,
  module: null,
};

const initialCompanyState: CompanyState = {
  company: null,
};

const initialFiscalYearState: FiscalYearState = {
  fiscal_year: null,
  // fiscal_year: fiscalYearStorage(),
};

const initialRefreshState: RefreshState = {};

// Action Definitions
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_ORIG_USER = 'SET_ORIG_USER';
export const ADD_IMPERSONATION = 'ADD_IMPERSONATION';
export const CLEAR_IMPERSONATION = 'CLEAR_IMPERSONATION';

export const UPDATE_NOTIFY_COUNT = 'UPDATE_NOTIFY_COUNT';

export const UPDATE_COURSE = 'UPDATE_COURSE';
export const UPDATE_MODULE = 'UPDATE_MODULE';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';

export const UPDATE_FISCAL_YEAR = 'UPDATE_FISCAL_YEAR';
export const UPDATE_ALT_FISCAL_YEAR = 'UPDATE_ALT_FISCAL_YEAR';

export const SEND_REFRESH = 'SEND_REFRESH';

export function fiscalYearStorage() {
  try {
    return JSON.parse(localStorage.getItem('fiscalYear'));
  } catch (err) {}
}

export function impersonationStorage() {
  try {
    return JSON.parse(localStorage.getItem('impersonations'));
  } catch (err) {}
}

// Reducer
export function authReducer(state: AuthState = initialState, action: Action): AuthState {
  // console.log('ngrx store ' + JSON.stringify(action));

  switch (action.type) {
    case SET_CURRENT_USER:
      return { ...state, CurrentUser: action.payload };
    case SET_ORIG_USER:
      return { ...state, OrigUser: action.payload };
    case CLEAR_IMPERSONATION:
      localStorage.removeItem('impersonations');
      return { ...state, Impersonations: [] };
    case ADD_IMPERSONATION:
      let Impe = Object.assign([], state.Impersonations);
      if (!Array.isArray(Impe)) {
        Impe = [];
      }

      const user = {} as any;
      user.UserID = action.payload.UserID;
      user.FirstName = action.payload.FirstName;
      user.LastName = action.payload.LastName;

      if (Impe.findIndex((user2) => user.UserID === user2.UserID) === -1) {
        Impe.push(user);
      }
      // let InList: Boolean = false;
      // for (let i = 0; i < Impe.length; i++) {
      //   if (Impe[i].UserID === action.payload.UserID) {
      //     InList = true;
      //   }
      // }
      // // If not in the list already, add it
      // if (!InList && action.payload.UserID !== state.OrigUser.UserID) {
      //   // Not in list and not the origional user.

      // }

      // if (Impe.length > 5) {
      //   Impe = Impe.slice(Impe.length - 5, 5);
      // }

      // todo: figure out the localstorage
      //  localStorage.setItem('currentUser', JSON.stringify(action.payload));
      localStorage.setItem('impersonations', JSON.stringify(Impe));
      return { ...state, Impersonations: Impe };
    default:
      return state;
  }
}

export function notificationReducer(state: NotificationState = initialNotifyState, action: Action): NotificationState {
  switch (action.type) {
    case UPDATE_NOTIFY_COUNT:
      return { ...state, notifyCount: action.payload };
    default:
      return state;
  }
}

export function courseModuleReducer(
  state: CourseModuleState = initialCourseModuleState,
  action: Action,
): CourseModuleState {
  switch (action.type) {
    case UPDATE_COURSE:
      return { ...state, course: action.payload };
    case UPDATE_MODULE:
      return { ...state, module: action.payload };
    default:
      return state;
  }
}

export function companyModuleReducer(state: CompanyState = initialCompanyState, action: Action): CompanyState {
  switch (action.type) {
    case UPDATE_COMPANY:
      return { ...state, company: action.payload };
    default:
      return state;
  }
}

export function fiscalYearModuleReducer(
  state: FiscalYearState = initialFiscalYearState,
  action: Action,
): FiscalYearState {
  switch (action.type) {
    case UPDATE_FISCAL_YEAR:
      //localStorage.setItem('fiscalYear', JSON.stringify(action.payload));
      return { ...state, fiscal_year: action.payload };
    default:
      return state;
  }
}

export function fiscalYearAltModuleReducer(
  state: FiscalYearState = initialFiscalYearState,
  action: Action,
): FiscalYearState {
  switch (action.type) {
    case UPDATE_ALT_FISCAL_YEAR:
      return { ...state, fiscal_year: action.payload };
    default:
      return state;
  }
}

export function refreshModuleReducer(state: RefreshState = initialRefreshState, action: Action): RefreshState {
  switch (action.type) {
    case SEND_REFRESH:
      return { ...state, fiscalyear: action.payload };
    default:
      return state;
  }
}
