import { Component, ViewChild} from '@angular/core';
import { AppSettings } from './app.settings';
import { Settings } from './app.settings.model';
import { CompanyService } from './services/company.service';
import { UserService } from './services/user.service';
import { Store } from '@ngrx/store';
import { IAppState } from './store';
import { AuthState } from './store/usersession/usersession.store';
import { InstituteService } from './services/institute.service';
import * as Highcharts from 'highcharts';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [CompanyService, InstituteService]
})
export class AppComponent {
  public settings: Settings;




  constructor( public appSettings: AppSettings, private companyService: CompanyService, private userService: UserService, private store: Store<IAppState>) {
      this.settings = this.appSettings.settings;
  }

  ngOnInit() { 

//     this.store.select('auth').subscribe((data: AuthState) => {
//       if (data) {
//           console.log('!!!! data');
//           this.CurrentUser = Object.assign({}, data.CurrentUser);
//           this.OrigUser = Object.assign({}, data.OrigUser);
//           this.Impersonations = data.Impersonations;
//           console.log('stringify: ' + JSON.stringify(this.CurrentUser));
//       } else {
//           console.log('!!!!! no data');
//       }
      
// });



//   setInterval(() => { this.ensureCurrentUser() }, 300000);

   // this.userService.ensureCurrentUser();


    this.companyService.getCompanies().subscribe(companies => {

    });

  }



}
