import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AppSettings } from '../app.settings';
import { Settings } from '../app.settings.model';
import { MenuService } from '../theme/components/menu/menu.service';
import { UserService } from '../services/user.service';
import { environment } from '../../environments/environment';
import { PusherService } from '../services/pusher.service';
import { Subscription } from 'rxjs';
import { KNotify } from '../services/notification';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  providers: [MenuService, PusherService],
})
export class PagesComponent implements OnInit {
  @ViewChild('sidenav', { static: false }) sidenav: any;
  public settings: Settings;
  public menus = ['vertical', 'horizontal'];
  public menuOption: string;
  public menuTypes = ['default', 'compact', 'mini'];
  public menuTypeOption: string;
  public isStickyMenu: boolean = false;
  public lastScrollTop: number = 0;
  public showBackToTop: boolean = false;
  public toggleSearchBar: boolean = false;
  private defaultMenu: string; //declared for return default menu when window resized
  public scrolledContent: any;
  keyneWeb = environment.keyneWeb;

  private notifySubscription: Subscription;

  constructor(
    public appSettings: AppSettings,
    public router: Router,
    private menuService: MenuService,
    private userService: UserService,
    private pusherService: PusherService,
    private _snackBar: MatSnackBar,
  ) {
    this.settings = this.appSettings.settings;
    this.notifySubscription = pusherService
      .getNotifyItems()
      .subscribe((notify: KNotify) => {
        if (notify.subject === 'goal') {
          this._snackBar.open('Goal Approved', 'Close', {
            duration: 3000,
          });
        } else if (notify.subject === 'pjr') {
          this._snackBar.open('Responsibility Approved', 'Close', {
            duration: 3000,
          });
        } else if (notify.subject === 'meeting') {
          this._snackBar.open('Meeting Approved', 'Close', {
            duration: 3000,
          });
        } else {
          this._snackBar.open(notify.subject, 'Close', {
            duration: 3000,
          });
        }
      });
  }

  ngOnInit() {
    if (window.innerWidth <= 768) {
      this.settings.menu = 'vertical';
      this.settings.sidenavIsOpened = false;
      this.settings.sidenavIsPinned = false;
    }
    this.menuOption = this.settings.menu;
    this.menuTypeOption = this.settings.menuType;
    this.defaultMenu = this.settings.menu;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.settings.loadingSpinner = false;
    }, 300);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (!this.settings.sidenavIsPinned) {
          this.sidenav.close();
        }
        if (window.innerWidth <= 768) {
          this.sidenav.close();
        }
      }
    });
    if (this.settings.menu == 'vertical')
      this.menuService.expandActiveSubMenu(this.menuService.getVerticalMenuItems());
  }

  public chooseMenu() {
    this.settings.menu = this.menuOption;
    this.defaultMenu = this.menuOption;
    this.router.navigate(['/']);
  }

  public chooseMenuType() {
    this.settings.menuType = this.menuTypeOption;
  }

  public changeTheme(theme) {
    this.settings.theme = theme;
  }

  public toggleSidenav() {
    this.sidenav.toggle();
  }

  public onPsScrollY(event) {
    this.scrolledContent = event.target;
    this.scrolledContent.scrollTop > 300
      ? (this.showBackToTop = true)
      : (this.showBackToTop = false);
    if (this.settings.menu == 'horizontal') {
      if (this.settings.fixedHeader) {
        var currentScrollTop =
          this.scrolledContent.scrollTop > 56 ? this.scrolledContent.scrollTop : 0;
        currentScrollTop > this.lastScrollTop
          ? (this.isStickyMenu = true)
          : (this.isStickyMenu = false);
        this.lastScrollTop = currentScrollTop;
      } else {
        this.scrolledContent.scrollTop > 56
          ? (this.isStickyMenu = true)
          : (this.isStickyMenu = false);
      }
    }
  }

  public scrollToTop() {
    var scrollDuration = 200;
    var scrollStep = -this.scrolledContent.scrollTop / (scrollDuration / 20);
    var scrollInterval = setInterval(() => {
      if (this.scrolledContent.scrollTop != 0) {
        this.scrolledContent.scrollBy(0, scrollStep);
      } else {
        clearInterval(scrollInterval);
      }
    }, 10);
    if (window.innerWidth <= 768) {
      this.scrolledContent.scrollTop = 0;
    }
  }

  @HostListener('window:resize')
  public onWindowResize(): void {
    if (window.innerWidth <= 768) {
      this.settings.sidenavIsOpened = false;
      this.settings.sidenavIsPinned = false;
      this.settings.menu = 'vertical';
    } else {
      this.defaultMenu == 'horizontal'
        ? (this.settings.menu = 'horizontal')
        : (this.settings.menu = 'vertical');
      this.settings.sidenavIsOpened = true;
      this.settings.sidenavIsPinned = true;
    }
  }

  public closeSubMenus() {
    let menu = document.querySelector('.sidenav-menu-outer');
    if (menu) {
      for (let i = 0; i < menu.children[0].children.length; i++) {
        let child = menu.children[0].children[i];
        if (child) {
          if (child.children[0].classList.contains('expanded')) {
            child.children[0].classList.remove('expanded');
            child.children[1].classList.remove('show');
          }
        }
      }
    }
  }
}
