<span *ngIf="(user$ | async)?.Impersonations?.length > 0"><button mat-button
        [matMenuTriggerFor]="menu"><mat-icon>admin_panel_settings</mat-icon></button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item *ngFor="let impersonate of (user$ | async)?.Impersonations"
            (click)="impersonateUser(impersonate)">
            {{impersonate.FirstName}}
            {{impersonate.LastName}}</button>
        <button mat-menu-item (click)="loadOrigUser()">
            {{(user$ | async)?.OrigUser.FirstName}}
            {{(user$ | async)?.OrigUser.LastName}}</button>
        <button mat-menu-item (click)="clear()">
            Clear</button>
    </mat-menu>
</span>