<mat-toolbar color="primary" [fxLayoutAlign]="(settings.menuType != 'mini') ? 'space-between center' : 'center center'"
    class="sidenav-header">
    <a *ngIf="settings.menuType == 'default'" mat-raised-button color="accent"
        href="{{ keyneWeb }}/k3/Content/Dashboard.html" class="small-logo">K</a>
    <a *ngIf="settings.menuType == 'default'" class="logo" routerLink="/" (click)="closeSubMenus()"></a>
    <svg *ngIf="settings.menuType != 'mini'" class="pin" (click)="settings.sidenavIsPinned = !settings.sidenavIsPinned">
        <path *ngIf="!settings.sidenavIsPinned" d="M16,12V4H17V2H7V4H8V12L6,14V16H11.2V22H12.8V16H18V14L16,12Z" />
        <path *ngIf="settings.sidenavIsPinned"
            d="M2,5.27L3.28,4L20,20.72L18.73,22L12.8,16.07V22H11.2V16H6V14L8,12V11.27L2,5.27M16,12L18,14V16H17.82L8,6.18V4H7V2H17V4H16V12Z" />
    </svg>
</mat-toolbar>

<div fxLayout="column" fxLayoutAlign="center center" class="user-block transition-2"
    [class.show]="settings.sidenavUserBlock">
    <div style="margin: 0; padding: 0;" [fxLayout]="(settings.menuType != 'default') ? 'column' : 'row'"
        [fxLayoutAlign]="(settings.menuType != 'default') ? 'center center' : 'space-around center'"
        class="user-info-wrapper">
        <img [src]="(user$ | async)?.CurrentUser.Icon" width="80" alt="user-image">
        <div class="user-info" style="margin: 0; padding: 0">
            <p style="margin: 0; padding: 0" class="name">{{(user$ | async)?.CurrentUser?.FirstName}} {{(user$ |
                async)?.CurrentUser.LastName}}</p>
            <p style="margin: 0; padding: 0" *ngIf="settings.menuType == 'default'" class="position">{{(user$ |
                async)?.CurrentUser.Title}}
                <!-- <br> <small class="muted-text">Member since May. 2016</small> -->
            </p>
            <p style="margin: 0; padding: 0"> {{(user$ | async)?.CurrentUser.Company.CompanyName}}</p>
        </div>
    </div>
    <div *ngIf="settings.menuType != 'mini'" fxLayout="row" fxLayoutAlign="space-around center"
        class="w-100 muted-text">
        <a mat-icon-button routerLink="/profile"><mat-icon>person_outline</mat-icon></a>
        <a mat-icon-button routerLink="/institute/notifications">

            <mat-icon *ngIf="(notify$ | async)?.notifyCount > 0; else other" [matBadge]="(notify$ | async)?.notifyCount"
                matBadgePosition="above after" matBadgeColor="warn">notifications_none</mat-icon>

            <ng-template #other>
                <mat-icon [matBadge]="(notify$ | async)?.notifyCount" matBadgePosition="above after"
                    matBadgeColor="gray">notifications_none</mat-icon>
            </ng-template>
        </a>
        <a mat-icon-button routerLink="/logout">
            <mat-icon>power_settings_new</mat-icon>
        </a>
    </div>
</div>

<div id="sidenav-menu-outer" class="sidenav-menu-outer" perfectScrollbar
    [class.user-block-show]="settings.sidenavUserBlock">
    <span *ngIf="!menuItems">loading....</span>
    <app-vertical-menu [menuItems]="menuItems" [menuParentId]="0"></app-vertical-menu>
</div>