import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { IAppState } from '../../../store';
import { Observable } from 'rxjs';
import { AuthState } from '../../../store/usersession/usersession.store';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserMenuComponent implements OnInit {
  public userImage: String;
  public title: String;
  public name: String;

  user$: Observable<AuthState>;

  constructor(public store: Store<IAppState>) {
    this.user$ = this.store.pipe(select('auth'));
  }

  ngOnInit() {
    this.user$.subscribe((evt) => {
      this.title = evt.CurrentUser.Title;
      this.name = evt.CurrentUser.FirstName + ' ' + evt.CurrentUser.LastName;
      this.userImage = evt.CurrentUser.Icon;
    });
  }
}
