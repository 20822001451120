import { ActionReducerMap, createSelector, createFeatureSelector, ActionReducer, MetaReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';

/**
 * storeFreeze prevents state from being mutated. When mutation occurs, an
 * exception will be thrown. This is useful during development mode to
 * ensure that none of the reducers accidentally mutates the state.
 */
import { storeFreeze } from 'ngrx-store-freeze';

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */

import {
  AuthState,
  authReducer,
  notificationReducer,
  NotificationState,
  courseModuleReducer,
  CourseModuleState,
  CompanyState,
  companyModuleReducer,
  fiscalYearModuleReducer,
  FiscalYearState,
  RefreshState,
  refreshModuleReducer,
  fiscalYearAltModuleReducer,
} from './usersession/usersession.store';
import { FiscalyearComponent } from '../fiscalyear/fiscalyear.component';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface IAppState {
  auth: AuthState;
  notify: NotificationState;
  courseModule: CourseModuleState;
  company: CompanyState;
  fiscalYear: FiscalYearState;
  fiscalYearAlt: FiscalYearState;
  refresh: RefreshState;
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const reducers: ActionReducerMap<IAppState> = {
  auth: authReducer,
  notify: notificationReducer,
  courseModule: courseModuleReducer,
  company: companyModuleReducer,
  fiscalYear: fiscalYearModuleReducer,
  fiscalYearAlt: fiscalYearAltModuleReducer,
  refresh: refreshModuleReducer,
};

// console.log all actions
// export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
//   return function(state: State, action: any): State {
//     console.log('state', state);
//     console.log('action', action);

//     return reducer(state, action);
//   };
// }

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<IAppState>[] = !environment.production ? [storeFreeze] : [];

/**
 * Layout Reducers
 */
//export const getLayoutState = createFeatureSelector<AuthState>('auth');

// export const getShowSidenav = createSelector(
//   getLayoutState,
//   fromLayout.getShowSidenav
// );
