import { Component, ComponentFactoryResolver, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router, ActivatedRouteSnapshot, UrlSegment, NavigationEnd, Params } from "@angular/router";
import { Title } from '@angular/platform-browser';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../store';
import { of, Observable } from 'rxjs';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {

    @ViewChild('container', { read: ViewContainerRef, static: false }) container: ViewContainerRef;

    public pageTitle: string;
    public breadcrumbs: {
        name: Observable<String>;
        url: string
    }[] = [];

    private params: Params;

    public settings: Settings;
    constructor(public appSettings: AppSettings,
        public router: Router,
        public activatedRoute: ActivatedRoute,
        public title: Title, public store: Store<IAppState>) {

        this.activatedRoute.params.subscribe(params => {
            this.params = params;
            this.settings = this.appSettings.settings;
            this.router.events.subscribe(event => {
                if (event instanceof NavigationEnd) {
                    this.breadcrumbs = [];
                    this.parseRoute(this.router.routerState.snapshot.root);
                    this.pageTitle = "";
                    // Not sure how this is going to work async
                    // this.breadcrumbs.forEach(breadcrumb => {
                    //     this.pageTitle += ' > ' + breadcrumb.name;
                    // })
                    this.title.setTitle(this.settings.name + this.pageTitle);
                }
            })
        });
    }

    private parseRoute(node: ActivatedRouteSnapshot, ) {
        if (node.data['breadcrumb']) {
            if (node.url.length) {
                let urlSegments: UrlSegment[] = [];
                node.pathFromRoot.forEach(routerState => {
                    urlSegments = urlSegments.concat(routerState.url);
                });
                let url = urlSegments.map(urlSegment => {
                    return urlSegment.path;
                }).join('/');

                var x: Observable<String> = null;
                try {
                    var y = new node.data['breadcrumb']();
                    y.setStore(this.store);
                    x = y.breadcrumb(node.params);
                } catch (err) {
                   // console.log("error: " + err)
                }

                this.breadcrumbs.push({
                    name: x ? x : of(node.data['breadcrumb']),
                    url: '/' + url
                })

            }
        }
        if (node.firstChild) {
            this.parseRoute(node.firstChild);
        }
    }

    public closeSubMenus() {
        let menu = document.querySelector(".sidenav-menu-outer");
        if (menu) {
            for (let i = 0; i < menu.children[0].children.length; i++) {
                let child = menu.children[0].children[i];
                if (child) {
                    if (child.children[0].classList.contains('expanded')) {
                        child.children[0].classList.remove('expanded');
                        child.children[1].classList.remove('show');
                    }
                }
            }
        }
    }


}
